/** @jsxImportSource @emotion/react */

import { StyleSheet, View } from "@react-pdf/renderer";
import { format2Frontend } from "../../../../../actions/Layers/util";
import { getFilledArrayOrDefault } from "../../../../../utils";
import { getFruitTableData } from "../../../AddItem/components/Summary/FruitTable";
import PDFArrivalCheck from "./PDFArrivalCheck";
import { PDFCheckFruitTables } from "./PDFCheckFruitTables";
import PDFContainerDefects from "./PDFContainerDefects";
import PDFDryMatterMinMaxAvgOverview from "./PDFDryMatterMinMaxAvgOverview";
import { PDFImages } from "./PDFImages";
import PDFLayerMeta from "./PDFLayerMeta";
import { PDFDocument, PDFPage } from "./PDFLayout";
import PDFPalletScoringOverview, { calculateStatusPercentages } from "./PDFPalletScoringOverview";
import { PDFPallets } from "./PDFPallets";


export const setPDFDataForIntakeReport = (config, user, all_defects, children, checks) => {
    // * Only use the pallets/children with intake check, set the check opbject on the pallet
    const children_with_intake = children.results
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((l) => {
            const merged_check = checks
                .filter((i) => i.layer_id === l.id)
                .reduce(
                    (acc, { avocados, defects, defect_list, images, ...i }) => ({
                        ...acc,
                        [`inspected_by_${i.location}`]: i.inspected_by,
                        avocados: [...acc.avocados, ...getFilledArrayOrDefault(avocados)],
                        images: [...acc.images, ...getFilledArrayOrDefault(images)],
                        defects: { ...acc.defects, ...defects },
                        defect_list: [...acc.defect_list, ...getFilledArrayOrDefault(defect_list)],
                        ...i
                    }),
                    {
                        avocados: [],
                        images: [],
                        defects: {},
                        defect_list: []
                    }
                );

            return {
                ...l,
                latest_check: merged_check,
            };
        }).filter((l) => l.latest_check?.test_id)
        .map(format2Frontend);

    // * Populate the intake check with fruit table data
    const updatedChildren = children_with_intake
        .map((child) => (
            // we overwrite the latest check here because the `/tests/{test_id}` endpoint will also return all images and underlying avocados while the layer endpoint does not
            {
                ...child,
                latest_check: {
                    ...child.latest_check,
                    label: `${child.label}`, // label for the images
                    table_data: getFruitTableData({ // this function generates the data for indivual fruit TODO: refactor to fields api
                        layer: child,
                        check: child.latest_check,
                        fruits: child.latest_check.avocados,
                        config,
                        user,
                        all_defects
                    }),
                    ...child.meta
                }
            }));

    const updatedChecks = updatedChildren.map((child) => child.latest_check);

    return { children: updatedChildren, checks: updatedChecks };

};


export const setPDFDataForStorageReport = (config, user, all_defects, children, checks) => {
    const formmated_checks_with_group_ids = checks.map((i) => ({
        ...i,
        group_id: `${i.layer_id}-${i.date}`
    }));

    const grouped = formmated_checks_with_group_ids.reduce((acc, { avocados, defects, defect_list, images, ...i }) => {
        if (!acc[i.group_id]) {
            // * merge all checks done on same layer on same day
            const layer = children.results.find((l) => l.id === i.layer_id);
            const layerDayGroup = {
                ...layer,
                group_id: i.group_id,
                layer_id: i.layer_id,
                date: i.date,
                timestamp: i.timestamp,
                latest_check: {
                    ...i,
                    [`inspected_by_${i.location}`]: i.inspected_by,
                    avocados: [...acc.avocados, ...getFilledArrayOrDefault(avocados)],
                    images: [...acc.images, ...getFilledArrayOrDefault(images)],
                    defects: { ...acc.defects, ...defects },
                    defect_list: [...acc.defect_list, ...getFilledArrayOrDefault(defect_list)],

                }
            };
            return {
                ...acc,
                [i.group_id]: layerDayGroup
            };
        }
        // * add to the already existing layer day group
        return {
            ...acc,
            [i.group_id]: { ...acc[i.group_id],
                latest_check: {
                    ...acc[i.group_id].latest_check,
                    [`inspected_by_${i.location}`]: i.inspected_by,
                    avocados: [...acc[i.group_id].avocados, ...getFilledArrayOrDefault(avocados)],
                    images: [...acc[i.group_id].images, ...getFilledArrayOrDefault(images)],
                    defects: { ...acc[i.group_id].defects, ...defects },
                    defect_list: [...acc[i.group_id].defect_list, ...getFilledArrayOrDefault(defect_list)]
                }
            }

        };
    }).values().map(format2Frontend).sort((a, b) => {
        if (a.label === b.label) {
            return a.timestamp - b.timestamp;
        }
        return a.label.localeCompare(b.label);
    });


    // * Populate the check with fruit table data
    const updatedChildren = grouped
        .map((child) => (
            // we overwrite the latest check here because the `/tests/{test_id}` endpoint will also return all images and underlying avocados while the layer endpoint does not
            {
                ...child,
                latest_check: {
                    ...child.latest_check,
                    label: `${child.label}`, // label for the images
                    table_data: getFruitTableData({ // this function generates the data for indivual fruit TODO: refactor to fields api
                        layer: child,
                        check: child.latest_check,
                        fruits: child.latest_check.avocados,
                        config,
                        user,
                        all_defects
                    }),
                    ...child.meta
                }
            }));

    const updatedChecks = updatedChildren.map((child) => child.latest_check);

    return { children: updatedChildren, checks: updatedChecks };

};


const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        height: "70vh",
        marginTop: "20px"
    },
    cell1: {
        width: "35%", // Adjust based on your layout
    },
    cell2: {
        width: "63%", // Adjust based on your layout
    },
    row2: {
        flexDirection: "row",
        justifyContent: "space-between",
        height: "50vh",
    },
});


export const TemplateGeneralIntakeReport = ({ onRender, pdf_config, general_status, layer_config, layer, data, config, all_defects, defect_groups, check_types }) => {
    // ! Warning: do not use any hooks that rely on the redux or api calls beyond this point. You will break the pdf rendering
    const { children_table_fields, children_title, children_note, logo_name, children_check_summary_meta_fields, check_meta_fields } = pdf_config as any;
    // TODO: remove fallback location.defect_groups as there can be multi chekc types in one report now.
    const layer_fields = layer_config.meta_display.map((i) => i.set_value({
        config,
        layer,
        check: layer.latest_check, // * To be used in check tables and layer overview
        fruit: null, // * Fruit list is not available on overview screen
        children: { count: data.children?.length || 0, results: getFilledArrayOrDefault(data.children, []) }, // * children is only available for layer index your are viewing
        form_options: config.form_options // * used to translate form values to labels
    }));

    const children_layer_fields = data.children.map((child) => {
        return children_check_summary_meta_fields.map((i) => i.clone().set_value({
            config,
            layer: child,
            check: child.latest_check, // * To be used in check tables and layer overview
            fruit: null,
            children: null,
            form_options: config.form_options // * used to translate form values to labels
        }));
    });

    const arrival_check_fields = layer_config.show_arrival_check?.fields.map((i) => i.clone().set_value({
        config,
        layer,
        check: layer.latest_check, // * To be used in check tables and layer overview
        fruit: null,
        children: null,
        form_options: config.form_options // * used to translate form values to labels
    }));

    const business_rules_status = layer?.[pdf_config.manual_flag_field];
    const percentages = calculateStatusPercentages(data.children, check_types);

    return (
        <>
            <PDFDocument onRender={onRender} title={`${(pdf_config.title || "Intake report")} ${layer?.label}`} >
                <PDFPage logo={logo_name} header_title={pdf_config.title || "Intake report"}>
                    <View style={styles.row} wrap={false}>
                        <View style={styles.cell1}>
                            <PDFLayerMeta layer={layer} fields={layer_fields} status={general_status} />
                        </View>
                        <View style={styles.cell2}>
                            {defect_groups.find((item) => item.total_defects_title) && <View style={styles.row2} wrap={false}>
                                {defect_groups.filter((item) => item.total_defects_title).map((group, index) => <PDFContainerDefects key={index} all_defects={all_defects} defect_group={group} printChecks={data.checks} />)}
                            </View>}
                            {pdf_config?.show_dry_matter && <PDFDryMatterMinMaxAvgOverview dryMatterMinMaxAvg={data.dryMatterMinMaxAvg} />}
                            <PDFPalletScoringOverview
                                status={business_rules_status}
                                percentages={percentages}

                            />
                        </View>
                    </View>
                </PDFPage>
                {
                    arrival_check_fields && <PDFPage>
                        <PDFArrivalCheck fields={arrival_check_fields} />
                    </PDFPage>
                }
                {children_table_fields.length > 0 && <PDFPallets
                    fields={children_table_fields}
                    printChildren={data.children}
                    config={config}
                    children_title={children_title}
                />}
                {getFilledArrayOrDefault(data.checks).map((i, index) => [<PDFCheckFruitTables
                    key={index + 0.1}
                    children_layer_fields={children_layer_fields}
                    header={i.table_data[0]}
                    children_note={i[children_note?.fieldname_getter]}
                    rows={i.table_data[1]}
                    footer={i.table_data[2]}
                    checkIndex={index}
                    all_defects={all_defects}
                    defect_groups={defect_groups}
                    check={i}
                    check_meta_fields={check_meta_fields}
                />,
                <PDFImages key={index + 0.2} images={i.all_images} />
                ])}
            </PDFDocument>
        </>
    );
};
