/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faArrowRight, faGrid, faList, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Col, Container, Row,
} from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { isFilledArray } from "../../../../utils";
import { Loading } from "../../../Helper/Loading";
import RipeWiseBreadcrumbs from "../RipeningRoomBreadcrumbs";
import { GroupStrategyModal } from "./GroupStrategyModal";
import useRipeningRoom, { LIST_OR_GRID, RipeningRoomProvider } from "./hooks/useRipeningRoom";
import { RipeWiseMetricMetaTable } from "./MetricCards";
import { AddSinglePalletModal, RemoveLayerModal, ScanToMoveLayerModal } from "./MovePalletsModal";
import RipeningCellConditionsForm from "./RipeningRoomConditionsForm";
import RipeningCellDays from "./RipeningRoomDays";
import RipeningCellItems from "./RipeningRoomItems";
import RipeningRoomMetaForm from "./RipeningRoomMetaForm";
import RipeningCellMetaTable from "./RipeningRoomMetaTable";
import { ScanToCheckModal } from "./StartCheckButton";

export default function RipeningRoomLayoutWrapped() {
    return <RipeningRoomProvider>
        <RipeningRoomLayout />
    </RipeningRoomProvider>;
}

function RipeningRoomLayout() {

    const config = useConfig();
    const navigate = useNavigate();

    const {
        listOrGrid,
        currentLocation,
        setListOrGrid,
        filterLevel,
        setFilterLevel,
        cellLevels,
        selectedLayersArray,
        fruit_type_config,
        refreshAllocatedLayers,
        deselectAll,
        pallets,
    } = useRipeningRoom();


    const [addPalletModalOpen, setAddPalletModal] = useState(false);
    const [movePalletsModalOpen, setMovePalletsModal] = useState(false);

    const onClose = () => {
        navigate("/ripening-room");
    };


    if (!currentLocation) {
        return <Loading />;
    }

    const roomIsNotEmpty = pallets?.length > 0 && fruit_type_config && fruit_type_config.ripening_field;


    // TODO: add color for ripewise
    return <div css={css`min-width:80%;`}>
        <Row className="flex-row-reverse g-0">
            <Col xxl="9" xl="8" lg="6" md="12">
                <div className="bg-white shadow-sm px-2 px-md-5">
                    <div className="px-3 pt-5 pb-5  mx-n3">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                                <h3 className="mb-0 ">{currentLocation?.label}</h3>
                            </div>

                            <div className="pb-2 pb-sm-0 d-flex align-items-center text-end ms-auto">
                                <Button className="btn-close my-1 ms-auto" size="lg" onClick={() => onClose()} ></Button>
                            </div>
                        </div>
                        <RipeWiseBreadcrumbs currentLocation={currentLocation} />
                    </div>
                    <RipeningCellDays />
                    <div css={css`min-height: 80vh;`}>
                        {roomIsNotEmpty && <div className="py-5">
                            <RipeningCellItems />
                        </div>}
                    </div>

                    {/* Ripening Cell Conditions Form */}
                    <RipeningCellConditionsForm />
                    <AddSinglePalletModal setModal={setAddPalletModal} modalOpen={addPalletModalOpen} />
                    <RemoveLayerModal
                        setModal={setMovePalletsModal}
                        modalOpen={movePalletsModalOpen}
                        selectedLayers={selectedLayersArray}
                        refreshAllocatedLayers={refreshAllocatedLayers}
                        deselectAll={deselectAll}
                    />

                    <div css={css`position: fixed;bottom: 0;left:0;right:0; z-index: 1000;`}>
                        <Container className="py-4">
                            <div className=" rounded shadow-lg py-4 px-4 bg-white" >
                                <Row>
                                    <Col xs="12" md="6">
                                        <div className="d-flex">
                                            <div className="pe-2">
                                                <Button className="text-nowrap" disabled={selectedLayersArray.length === 0} color={selectedLayersArray.length === 0 ? "light" : "primary"} onClick={() => setMovePalletsModal(true)}><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> Move {selectedLayersArray.length}</Button>
                                            </div>
                                            <div className="pe-2">
                                                <RipeningRoomMetaForm/>
                                            </div>
                                            <GroupStrategyModal />
                                            <div className="pe-2">
                                                <Button color="light" className="text-nowrap" onClick={() => setListOrGrid((prev: string) => (prev === LIST_OR_GRID.LIST ? LIST_OR_GRID.GRID : LIST_OR_GRID.LIST))}>
                                                    {listOrGrid === LIST_OR_GRID.LIST && <span><FontAwesomeIcon icon={faGrid}></FontAwesomeIcon> Grid</span>}
                                                    {listOrGrid === LIST_OR_GRID.GRID && <span><FontAwesomeIcon icon={faList}></FontAwesomeIcon> List</span>}
                                                </Button>
                                            </div>
                                            {listOrGrid === LIST_OR_GRID.GRID && cellLevels.length > 0 && cellLevels.map((level, index) => <div key={index} className="pe-2">
                                                <Button className="text-nowrap" color={filterLevel === level ? "primary" : "light"} onClick={() => setFilterLevel(level)}>{level}</Button>
                                            </div>)}
                                        </div>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <div className="d-flex justify-content-start justify-content-md-end pt-2 pt-md-0">
                                            <ScanToMoveLayerModal />
                                            <ScanToCheckModal />
                                            <div className="ms-2">
                                                <Button
                                                    className="text-nowrap"
                                                    color="primary"
                                                    onClick={() => setAddPalletModal(true)}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add pallets</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
            </Col>
            <Col xxl="3" xl="4" lg="6" md="12">
                <div className="pt-5 mt-5 px-2 px-md-5 ">

                    <div className="mt-5">
                        <RipeWiseMetricMetaTable />
                    </div>

                    {
                        isFilledArray(config.root_config?.ripening_room_sidebar_meta_fields_1) && <div className="mt-5 pt-5 border-top">
                            <RipeningCellMetaTable fields={config.root_config.ripening_room_sidebar_meta_fields_1} />

                        </div>
                    }
                    {
                        isFilledArray(config.root_config?.ripening_room_sidebar_meta_fields_2) && <div className="mt-5 pt-5 border-top">
                            <RipeningCellMetaTable fields={config.root_config.ripening_room_sidebar_meta_fields_2} />
                        </div>
                    }
                </div>
            </Col>
        </Row>

    </div >;

}

