/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { PDFViewer } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Button, Container
} from "reactstrap";
import { AnyAction } from "redux";
import {
    getLayer,
    resetLayerState
} from "../../../../../actions/Layers/actions";
import { CHECK_LOCATION, VIEW } from "../../../../../actions/Layers/constants";
import { Location } from "../../../../../actions/Navigation/constants";
import useConfig from "../../../../../actions/Tenants/config/configHook";
import { calculateMinMaxAvg } from "../../../../../actions/Tenants/config/utils";
import { getFilledArrayOrDefault } from "../../../../../utils";
import useAuthorization from "../../../../../utils/authorization";
import { fetchBlob } from "../../../../../utils/base64";
import { callAvosApi } from "../../../../../utils/useAvosApiHook";
import { TenantDefect, useDefectsHook } from "../../../../Forms/useManagedOptionsHook";
import LoadingProgressBar from "../../../../Helper/LoadingProgressBar";
import { SendReportButton } from "../SendReportButton";
import { setPDFDataForAdvanceRipeningReport, TemplateAdvanceRipeningReport } from "./PDFAdvanceRipeningReport";
import { setPDFDataForIntakeReport, setPDFDataForStorageReport, TemplateGeneralIntakeReport } from "./PDFIntakeReport";
import PdfReader from "./PDFReader";
import QCStatusButton from "./QCStatusButton";
import { prepareChecksForPDF, ReportTemplate } from "./utils";

export const PDFCountDownText = [
    {
        text: "Loading data...",
        seconds: 3
    },
    {
        text: "Fetching checks...",
        seconds: 3
    },
    {
        text: "List all the fruit...",
        seconds: 3
    },
    {
        text: "Fetching images...",
        seconds: 3
    },
    {
        text: "Rendering PDF...",
        seconds: 4
    },
    {
        text: "Fit images on page...",
        seconds: 4
    },
    {
        text: "Almost there...",
        seconds: 5
    },
    {
        text: "Finishing up...",
        seconds: 3
    }
];


export default function PDFLayerReport() {
    const config = useConfig();
    const dispatch = useDispatch();
    const user = useSelector<any>((state) => state.auth.user);
    const layer = useSelector<any, any>((state) => state.layers.current);
    const tenantsIsLoading = useSelector<any>((state) => state.tenants.isLoading);
    const [data, setData] = useState<any>();
    const params = useParams();
    const layer_config = config.get_layer_config(layer);

    const all_defects = useDefectsHook(layer.fruit_type) as TenantDefect[];

    const report_config_value = params.config_value;
    const report_config = config.get_pdf_report(layer, report_config_value);
    const browse_history = useSelector<any, Location[]>((state) => state.navigation.browse_history);
    const lastBrowse = browse_history[browse_history.length - 1];
    const previousPath = lastBrowse?.pathname || `/layer/${params?.layer_id}`;

    const draft_report_key = `report_eye_${report_config_value}_generated`;
    const draft_report = draft_report_key ? layer[draft_report_key] : undefined;

    const check_types = getFilledArrayOrDefault(report_config?.check_types, [report_config?.template === ReportTemplate.advance_ripening ? CHECK_LOCATION.ADVANCE_RIPENING : report_config_value]);
    const check_types_string = check_types.join(",");

    const navigate = useNavigate();
    useEffect(() => {
        dispatch(resetLayerState());
        dispatch(getLayer(params.layer_id!) as unknown as AnyAction);
    }, [params.layer_id]);

    // We need a local state here becuase the pdf rendered cannot reach the react store
    useEffect(() => {
        // * Do not load all this data if the report is already generated
        if (!layer.id || !report_config?.value || (all_defects?.length || 0) === 0 || layer?.id !== params.layer_id || !check_types_string || draft_report) {
            return;
        }
        const fetchData = async () => {
            // fetching children
            try {
                const response = await callAvosApi(`/layers/${layer.id}/children`);
                const children = response.data;
                const checks_response = await callAvosApi(`/layers/${layer.id}/checks`, {
                    params: {
                        with_fruit: true,
                        with_meta: true,
                        check_location: check_types_string,
                        view_strategy: VIEW.CHILDREN
                    }
                });
                const checks = checks_response.data;

                // * get formatted intake checks, preload images and replace URLs with Blob URLs
                const formatted_checks = await prepareChecksForPDF(checks);
                let dryMatterMinMaxAvg = {};
                if (report_config?.show_dry_matter) {
                    const lab_check_response = await callAvosApi(`/layers/${layer.id}/checks`, { params: { view_strategy: VIEW.CHILDREN, check_location: CHECK_LOCATION.LAB_CHECK, with_fruit: true } });
                    if (lab_check_response.data) {
                        const dryMatterValues = lab_check_response.data
                            .flatMap((item) => item.avocados)
                            .filter((i) => i.manual_dry_matter)
                            .map((i) => i.manual_dry_matter);
                        dryMatterMinMaxAvg = calculateMinMaxAvg(dryMatterValues, "");

                    }
                }

                if (report_config.template === ReportTemplate.intake) {
                    const theData = setPDFDataForIntakeReport(config, user, all_defects, children, formatted_checks);
                    setData({ ...theData, dryMatterMinMaxAvg, loaded: true });
                }
                if (report_config.template === ReportTemplate.storage) {
                    const theData = setPDFDataForStorageReport(config, user, all_defects, children, formatted_checks);
                    setData({ ...theData, dryMatterMinMaxAvg, loaded: true });
                }

                if (report_config.template === ReportTemplate.advance_ripening) {
                    const theData = setPDFDataForAdvanceRipeningReport(children, formatted_checks);
                    setData({ ...theData, dryMatterMinMaxAvg, loaded: true });
                }


            } catch (error) {
                toast.error("Could not prepare data for PDF report");
                console.error("Error fetching data:", error); // eslint-disable-line no-console
            }
        };

        fetchData();


    }, [layer.id, all_defects?.length, draft_report, check_types_string, report_config?.value]);

    const isLoading: boolean = !draft_report && (!layer_config || !data?.loaded || !report_config || tenantsIsLoading || all_defects.length === 0) as boolean;

    // * Setup flag fields
    const business_rules_status = layer?.[report_config?.manual_flag_field];
    const general_status = layer?.[report_config?.business_rule_flag_fiel] || business_rules_status;

    const [pdfBlob, setPdfBlob] = useState<Blob>();
    const onRender = async ({ blob }) => {
        if (!pdfBlob || pdfBlob.size !== blob.size) {
            setPdfBlob(blob);
        }
    };

    const auth = useAuthorization();
    const canSendReport = report_config?.show_send_email_button || auth.userBelongsToOneOfTeams(report_config?.teams_that_can_send);

    useEffect(() => {
        // * fetch the blob if the draft report is set to send the active draft report
        const fetchAndSetBlob = async () => {
            try {
                const blob = await fetchBlob(draft_report);
                setPdfBlob(blob);
            } catch (error) {
                console.error("Error fetching the blob:", error); // eslint-disable-line no-console
                setPdfBlob(undefined);
            }
        };

        if (draft_report) fetchAndSetBlob();
    }, [draft_report]);

    return <div className="px-3 py-4 bg-gray">
        <Container>
            <div className="d-flex justify-content-end align-items-center">
                <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                    <h3 className="mb-0 ">{layer_config?.text} {layer.label} </h3>
                </div>
                <div className="pb-2 pb-sm-0 d-flex align-items-center ms-auto">
                    <QCStatusButton pdf_config={report_config} pdfBlob={pdfBlob} reportId={report_config_value} draft_report_key={draft_report_key} />
                    {canSendReport && <SendReportButton
                        layerId={layer.id}
                        blob={pdfBlob}
                        config_value={report_config_value as string}
                        status={general_status}
                    />}
                    <Button className="btn-close my-1" size="lg" onClick={() => navigate(previousPath)} ></Button>
                </div>
            </div>
        </Container>
        <Container className="py-5">

            {isLoading ? (
                <LoadingProgressBar text={PDFCountDownText.slice(0, 4)} />
            ) : (
                <div>
                    {!pdfBlob && !draft_report && <LoadingProgressBar text={PDFCountDownText} startIndex={4} />}
                    {!draft_report && <div css={css`margin:auto;width: 420mm;`} >
                        <PDFViewer style={{ width: "100%", height: "1200px", opacity: pdfBlob ? 1 : 0 }}>
                            <>
                                {report_config.template === ReportTemplate.advance_ripening && <TemplateAdvanceRipeningReport
                                    onRender={onRender}
                                    pdf_config={report_config}
                                    general_status={general_status}
                                    layer_config={layer_config}
                                    layer={layer}
                                    data={data}
                                    config={config}
                                />}
                                {report_config.template !== ReportTemplate.advance_ripening && <TemplateGeneralIntakeReport
                                    onRender={onRender}
                                    pdf_config={report_config}
                                    general_status={general_status}
                                    layer_config={layer_config}
                                    layer={layer}
                                    data={data}
                                    all_defects={all_defects}
                                    defect_groups={getFilledArrayOrDefault(report_config.defect_groups)}
                                    config={config}
                                    check_types={check_types}
                                />}


                            </>
                        </PDFViewer>
                    </div>}
                    {draft_report && <PdfReader pdfUrl={draft_report} />}
                </div>)
            }
        </Container >
    </div >;
}

