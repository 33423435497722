import { fruitAge2Date, julianCode2packingDate, packingDate2JulianCode } from "../../../components/Helper/DatePicker";
import { defaultFruitType } from "../../../components/scenes/Tenants/Forms/FruitTypeConfigForm";
import { applyDisplayFilter, applyDisplayFilterFields, applyDisplayFilterLayerFields } from "./applyDisplayFilter";
import { BaseCheckLocation, CheckLocation, LayerTypeConfig, RootConfigType, Stage } from "./constantsTyped";
import { fruitFormField } from "./fruitFormFields";
import { ALL_FRUIT } from "./fruitNames";
import { calculateAbsoluteDifference, countCategory, find_category, value_to_category } from "./utils";

// summary fields
const CHECK_LOCATION = "check_location";
const LAYER_TYPE = "layer_type";
const STAGE = "stage";
const PDF_REPORT = "pdf_report";
const FRUIT_TYPE = "fruit_type";
const COLOR_CODE = "color_code";
const ROOT = "root";
export const CONFIG_TYPES = {
    LAYER_TYPE,
    STAGE,
    CHECK_LOCATION,
    PDF_REPORT,
    FRUIT_TYPE,
    COLOR_CODE,
    ROOT,
};

// TODO: convert to structure like actions/layer/fields
export const summary_field_avocado_index = "summary_field_avocado_index";
export const summary_field_avos_firmness = "summary_field_avos_firmness";
export const summary_field_avos_firmness_raw = "summary_field_avos_firmness_raw";
export const summary_field_pressure = "summary_field_pressure";
export const summary_field_pressure_prediction = "summary_field_pressure_prediction";
export const summary_field_external_defects = "summary_field_external_defects";
export const summary_field_internal_defects = "summary_field_internal_defects";
export const summary_field_major_defects = "summary_field_major_defects";
export const summary_field_minor_defects = "summary_field_minor_defects";
export const summary_field_color = "summary_field_color";
export const summary_field_weight = "summary_field_weight";
export const summary_field_weight_kg = "summary_field_weight_kg";
export const summary_field_manual_quality = "summary_field_manual_quality";
export const summary_field_atron_weight = "summary_field_atron_weight";
export const summary_field_brix = "summary_field_brix";
export const summary_field_dry_matter = "summary_field_dry_matter";
export const summary_field_dry_matter_felix = "summary_field_dry_matter_felix";
export const summary_field_dry_matter_class = "summary_field_dry_matter_class";
export const summary_field_internal_color = "summary_field_internal_color";
export const summary_field_external_color = "summary_field_external_color";
export const summary_field_is_shiny = "summary_field_is_shiny";
export const summary_field_maturity = "summary_field_maturity";
export const summary_field_temperature = "summary_field_temperature";
export const summary_field_fruit_variety = "summary_field_fruit_variety";
export const summary_field_predicted_ripening_stage = "summary_field_predicted_ripening_stage";
export const summary_field_manual_ripening_stage = "summary_field_manual_ripening_stage";
export const summary_field_ripening_stage_firmness = "summary_field_ripening_stage_firmness";
export const summary_field_pressure_color_code_by_firmness_class = "summary_field_pressure_color_code_by_firmness_class";
export const summary_field_taste = "summary_field_taste";
export const summary_field_penetro_pressures = "summary_field_penetro_pressures";
export const summary_field_durometer_pressures = "summary_field_durometer_pressures";
export const summary_field_pressure_classes = "summary_field_pressure_classes";
export const summary_field_plu = "summary_field_plu";
export const summary_field_box_size = "summary_field_box_size";
export const summary_field_fruit_firmness_class = "summary_field_fruit_firmness_class";
export const summary_field_underweight = "summary_field_underweight";
export const summary_field_mouth_ripeness = "summary_field_mouth_ripeness";
export const summary_field_flavour_nuttiness = "summary_field_flavour_nuttiness";
export const summary_field_flavour_creamy = "summary_field_flavour_creamy";
export const summary_field_lab_check_dm_avg = "summary_field_lab_check_dm_avg";
export const summary_field_gross_weight = "summary_field_gross_weight";
export const summary_field_net_weight = "summary_field_net_weight";
export const summary_field_caliber = "summary_field_caliber";
export const summary_field_first_impression = "summary_field_first_impression";
export const summary_field_texture_consistency = "summary_field_texture_consistency";
export const summary_field_colors = "summary_field_colors";
export const summary_field_maturity_string = "summary_field_maturity_string";
export const summary_field_box_weight_empty = "summary_field_box_weight_empty";
export const summary_field_box_weight_gross = "summary_field_box_weight_gross";
export const summary_field_box_weight_net = "summary_field_box_weight_net";
export const summary_field_box_index = "summary_field_box_index";
export const summary_field_fruit_diameter = "summary_field_fruit_diameter";
export const summary_field_lenticel = "summary_field_lenticel";
export const summary_field_translucency = "summary_field_translucency";
export const summary_field_remarks = "summary_field_remarks";
export const summary_field_acidity = "summary_field_acidity";

// Add check pages with their titles
// TODO: convert to enum
export const path_atron_results = "atron-results";
export const path_find_pallet = "find-pallet";
export const path_atron = "scanning";
export const path_mangos = "mangos";
export const path_form = "form";

export const path_external_quality_check = "external-quality-check";
export const path_fta_input = "fta-input";
export const path_weight_input = "weight-input";
export const path_weight_input_kg = "weight-input-kg";
export const path_internal_quality_check = "internal-quality-check";
export const path_pressure_validation = "pressure-validation";
export const path_color_check = "color-check";
export const path_internal_color_check = "internal-color-check";
export const path_brix_input = "brix-input";
export const path_dry_matter = "dry-matter";
export const path_maturity = "maturity-input";
export const path_temperature = "temperature-input";
export const path_power_bi = "power-bi";
export const path_manual_check = "manual-check";
export const path_manual_or_with_mini = "manual-or-with-mini";
export const path_manual_fruit_form = "manual-check"; //  renamed it to manual-check since path_manual_fruit_form or path_manual_check are the same
export const path_visual_check = "visual-check";
export const path_results_with_form = "results-with-form";
export const path_summary = "summary";
export const path_taste = "taste";
export const path_durometer = "durometer";
export const path_penetrometer = "penetrometer";
export const path_has_internal_quality_check = "has-internal-quality-check";
export const path_ask_quality = "ask-quality";
export const path_fruit_images = "fruit-images";
export const path_plu = "path-plu";
export const path_pressure_lbs = "path_pressure_lbs";
export const path_pressure_kg = "path_pressure_kg";
export const path_pit_size = "path_pit_size";
export const path_advance_ripening = "advance-ripening";

// QC pages
export const pages = {
    [path_find_pallet]: "Search Pallet",
    [path_atron]: "Scan",
    [path_mangos]: "Scan", // only collecting data
    [path_atron_results]: "Scan", // Collecting data AND showing results
    [path_form]: "Feedback Form",
    [path_internal_quality_check]: "Internal Quality Check",
    [path_external_quality_check]: "External Quality Check",
    [path_fta_input]: "Pressure Input",
    [path_weight_input]: "Weight Input",
    [path_summary]: "Summary",
    [path_pressure_validation]: "Pressure validation",
    [path_color_check]: "External Color Check",
    [path_internal_color_check]: "Internal Color Check",
    [path_brix_input]: "Brix input",
    [path_dry_matter]: "Dry matter",
    [path_maturity]: "Maturity",
    [path_temperature]: "Temperature",
    [path_penetrometer]: "Penetrometer",
    [path_durometer]: "Durometer",
    [path_fruit_images]: "Images",
    [path_pit_size]: "Pit size"
};

// Default translations
export const lang = {
    pressure: "Pressure",
};

// Different flows for add fruit
export const default_intake_flow = [path_atron_results, path_weight_input, path_color_check, path_external_quality_check, path_fta_input, path_internal_quality_check, path_summary];
export const default_summary_fields_intake = [
    summary_field_avocado_index,
    summary_field_manual_quality,
    summary_field_avos_firmness,
    summary_field_color,
    summary_field_external_defects,
    summary_field_pressure,
    summary_field_internal_defects,
];

export const default_data_capturing_flow = [path_atron_results, path_fta_input, path_summary];
export const default_summary_fields_data_capturing = [
    summary_field_avocado_index,
    summary_field_avos_firmness_raw,
    summary_field_avos_firmness,
    summary_field_pressure,
];
export const missionBreda_summary_fields_data_capturing = [
    summary_field_avocado_index,
    summary_field_avos_firmness,
    summary_field_pressure,
    summary_field_pressure,
    summary_field_predicted_ripening_stage,
];
export const tropsSpain_summary_fields_data_capturing = [
    summary_field_avocado_index,
    summary_field_pressure_prediction,
    summary_field_predicted_ripening_stage,
];

export const default_follow_the_box_flow = [path_atron_results, path_summary];
export const default_summary_fields_follow_the_box = [
    summary_field_avocado_index,
    summary_field_avos_firmness_raw,
    summary_field_avos_firmness,
];

export const flow_final_check = [path_atron_results, path_color_check, path_external_quality_check, path_internal_quality_check, path_summary];
export const summary_fields_final_check = [
    summary_field_avocado_index,
    summary_field_manual_quality,
    summary_field_weight,
    summary_field_avos_firmness,
    summary_field_color,
    summary_field_external_defects,
    summary_field_pressure,
    summary_field_pressure_prediction,
    summary_field_internal_defects,
];

export const default_location_config: BaseCheckLocation = {
    show_atron_color: false,
    show_atron_results_bottom_summary: true,
    // meta_form_summary: false,
    // device_result_action: "scanning",
    show_pressure_prediction: false,
    fruit_types: ALL_FRUIT,
    required_sample_size: 2
};

export const ripening_colors = {
    RTE: "#9A6804",
    Triggered: "#049510",
    Hard: "#015D09",
    "RTE+": "#885C02",
    "Triggered+": "#017F0B",
    Soft: "brown",
    Ripe: "#049510",
    "Nearly ripe": "#D9B300",
    Overripe: "#FFA500",
    Medium: "#049510",
    Firm: "#D9B300",
    Hard_Mission: "#D64550",
    Red: "#FF0000",
    Green: "#089000"
};

export const ripening_stages_firmness = [
    {
        gte: false,
        lt: 10,
        label: "Overripe",
        color: ripening_colors.Overripe
    },
    {
        gte: 10,
        lt: 31,
        label: "Soft",
        color: ripening_colors.Soft
    },
    {
        gte: 31,
        lt: 50,
        label: "Medium",
        color: ripening_colors.Medium
    },
    {
        gte: 50,
        lt: 71,
        label: "Firm",
        color: ripening_colors.Firm
    },
    {
        gte: 71,
        lt: 200,
        label: "Hard",
        color: ripening_colors.Hard
    },
];

export const pressure_classes = [
    {
        gte: false,
        lt: 7,
        label: "Ripe",
        color: ripening_colors.Ripe
    },
    {
        gte: 7.1,
        lt: 12,
        label: "Nearly ripe",
        color: ripening_colors["Nearly ripe"]
    },
    {
        gte: 12,
        lt: false,
        label: "Hard",
        color: ripening_colors.Hard
    },
];

export const headerLinks = {
    home: "home",
    settings: "settings",
    // checks: "checks",
    addCheck: "addCheck",
    logout: "logout",
    insights: "insights",
    labelling: "labelling",
    packinglist: "packinglist",
    deviceManagement: "deviceManagement",
    tenants: "tenants",
    ripening: "ripening",
    waste: "waste",
    switchTenant: "switchTenant",
    mailbox: "mailbox",
    admin: "admin",
    tabCategoryOperation: "tab_category.operation",
    tabCategoryStock: "tab_category.stock",
    tabCategoryQCPlanning: "tab_category.qc_planning",
    tabCategoryQCReport: "tab_category.report",
    tabCategoryTransit: "tab_category.transit",
};

type Box = {
    box_weight?: "4" | "9" | "10";
    box_size: number;
    box_count?: number;
};

type SetValueFunction = (key: string, value: number) => void;

const setSampleSize = ({ box_weight, box_size, box_count = 1 }: Box, setValue: SetValueFunction): void => {
    if (box_weight && box_size) {
        let sample_size = 0;
        if (box_weight === "4") {
            sample_size = box_size * box_count;
        } else if (box_weight === "9") {
            sample_size = box_size * 2.25 * box_count;
        } else if (box_weight === "10") {
            sample_size = box_size * 2.5 * box_count;
        }
        setValue("sample_size", sample_size);
    }
};

const setMissionSampleSizeAvocado = ({ box_weight, box_size }: Box, setValue: SetValueFunction): void => {
    if (box_weight && box_size) {
        let sample_size = 0;
        if (box_weight === "4") {
            sample_size = box_size * 4;
        } else if (box_weight === "10") {
            sample_size = box_size * 5;
        }
        setValue("sample_size", sample_size);
    }
};

const setMissionSampleSizeMango = ({ box_count, box_size }: Box, setValue: SetValueFunction): void => {
    if (box_count && box_size) {
        const sample_size = box_count * box_size;
        setValue("sample_size", sample_size);
    }
};

export const baseConfig: RootConfigType = {
    home_page: "/",
    fruit_types: [],
    check_locations: [],
    layer_types: [],
    pdf_reports: [],
    stages: [],
    get_fruit_type(fruit_type: string) {
        const fruitTypesArray = Array.isArray(this.fruit_types) ? this.fruit_types : [];
        return fruitTypesArray.find((i: any) => i.value === fruit_type) || defaultFruitType;
    },
    get_fruit_type_external_colors(pallet) {
        const fruit_type = this.get_fruit_type(pallet.fruit_type);
        if (fruit_type.external_colors) {
            return fruit_type.external_colors;
        }
        return false;
    },
    get_fruit_type_internal_colors(pallet) {
        const fruit_type = this.get_fruit_type(pallet.fruit_type);
        if (fruit_type.internal_colors) {
            return fruit_type.internal_colors;
        }
        return false;
    },
    // default_fruit_type: AVOCADO_FRUIT, // This stopped working ?
    default_fruit_type: "avocado",
    countCategory,
    has_purchase_order_number: false,
    default_manual: false,
    can_do_manual_check: false,
    summary_collapsed: true,
    ripening_target_options: false,
    show_atron_pressure_range_kg: false,

    indicate_soft_avocado_at_internal: false,
    indicate_quality_at_defects: true,
    suggest_color_stage: false,
    default_location: "intake",
    summary_like_question: false,
    strict_check_size: true,
    research_mode: true,
    bakker: false,
    invalidate_20x_firmness: true,
    show_device_toggle: false,

    pages,
    lang,
    first_check_page(layer, check, currentPage) {
        if (!currentPage || !layer.type) {
            return false;
        }
        const checkLocation = this.get_location(layer, check);

        if (checkLocation) {
            const { flow } = checkLocation;
            const first = flow[0];
            if (first === currentPage) {
                return false;
            }

            return { text: this.pages[first], path: first };
        }
        return false;
    },
    next_check_page(layer, check, currentPage) {
        if (!currentPage || !layer.type) {
            return false;
        }
        const checkLocation = this.get_location(layer, check);
        if (checkLocation) {
            const { flow } = checkLocation;
            const index = flow.indexOf(currentPage);
            if (index > -1) {
                const next = flow[index + 1] || false;
                if (next === false) {
                    return false;
                }
                return { text: this.pages[next], path: next };
            }
        }
        return false;
    },
    // after_device_step(layer, check) {
    //     const checkLocation = this.get_location(layer, check);
    //     if (checkLocation) {
    //         const { flow } = checkLocation;
    //         const next = flow[1];
    //         return { text: this.pages[next], path: next };
    //     }
    //     return false;
    // },
    prev_check_page(layer, check, currentPage) {
        if (!currentPage || !layer?.type) {
            return false;
        }
        const checkLocation = this.get_location(layer, check);

        if (checkLocation) {
            const { flow } = checkLocation;
            const index = flow.indexOf(currentPage);
            if (index > 0) {
                const prev = flow[index - 1] || false;
                if (prev === false) {
                    return false;
                }
                // advance ripening is a special case and actual a summary page, is handled seperaterly in ManualCheckNavButtons
                if (prev === path_advance_ripening) {
                    return false;
                }

                return { text: this.pages[prev], path: prev };
            }
        }
        return false;
    },
    get_layer_pdf_reports(layer) {
        // * TODO: merge pdf and check type config.
        // * On pdf reports...the layer_type filter is configured in the root. not in `.display_filter`
        // * Hence the double filter
        return applyDisplayFilter(this.pdf_reports, { fruit_type: layer.fruit_type }).filter((i) => i.layer_type === layer.type);
    },

    get_pdf_report(layer, report_value) {
        const report = this.pdf_reports.find((i) => i.value === report_value);
        if (!report) {
            return undefined;
        }
        const filter = { layer_type: layer.type, fruit_type: layer.fruit_type };
        return {
            ...report,
            children_table_fields: applyDisplayFilterLayerFields(report.children_table_fields, filter),
            children_check_summary_meta_fields: applyDisplayFilterLayerFields(report.children_check_summary_meta_fields, filter),
            check_meta_fields: applyDisplayFilterLayerFields(report.check_meta_fields, filter),
            pallet_meta_fields: applyDisplayFilterLayerFields(report.pallet_meta_fields, filter),
            defect_groups: applyDisplayFilterFields(report.defect_groups, filter),
        };
    },


    // * Get locations for layer, attributes will be unfiltered
    get_locations(layer) {
        // * only filter on layer properties here
        const filter = { layer_type: layer.type, fruit_type: layer.fruit_type };
        return applyDisplayFilter(this.check_locations, filter).map((rawLocation) => this.filter_location_fields(rawLocation, filter)) as CheckLocation[];

    },

    // * Get location config for check on layer. Will filter all fields for you
    get_location(layer, check) {

        const rawLocation = this.check_locations.find((check_config) => check_config.value === check.location);
        if (!rawLocation) {
            return undefined;
        }
        const filter = { layer_type: layer.type, fruit_type: layer.fruit_type, is_manual: check.is_manual };
        return this.filter_location_fields(rawLocation, filter) as CheckLocation;

    },
    filter_location_fields(rawLocation, filter) {
        // * setup forms, summary fields etc etc

        try {
            return {
                ...rawLocation,
                flow: applyDisplayFilterFields(rawLocation.flow, filter).map((i) => i.path), // For now  only interested in the legacy path
                inline_form: applyDisplayFilterFields(rawLocation.inline_form, filter).map((i) => i.field_type), // For now  only interested in the legacy path
                // we try to extend the fruitFormField... needed to support image radio button which needs some functions and image references to work
                // I would like to prevent this, but it is the easiest way to get the image radio button to work for now
                fruit_form: applyDisplayFilterFields(rawLocation.fruit_form, filter).map((i) => (fruitFormField?.[i.form_field_id] ? { ...fruitFormField?.[i.form_field_id], ...i } : i)), // returns form fields
                meta_form_summary: applyDisplayFilterFields(rawLocation.meta_form_summary, filter), // returns form fields
                meta_form_layer: applyDisplayFilterFields(rawLocation.meta_form_layer, filter), // returns form fields
                pre_check_form: applyDisplayFilterFields(rawLocation.pre_check_form, filter), // returns form fields
                visual_check_form: applyDisplayFilterFields(rawLocation.visual_check_form, filter), // returns form fields
                required_images: applyDisplayFilterFields(rawLocation.required_images, filter), // returns required images array for check level
                required_fruit_images: applyDisplayFilterFields(rawLocation.required_fruit_images, filter), // returns required images array for fruit level
                summary_fruit_fields: applyDisplayFilterFields(rawLocation.summary_fruit_fields, filter).map((i) => i.field_id), // For now  only interested in the legacy summary_field id
                summary_layer_fields_1: applyDisplayFilterLayerFields(rawLocation.summary_layer_fields_1, filter), // returns layer fields_1
                summary_layer_fields_2: applyDisplayFilterLayerFields(rawLocation.summary_layer_fields_2, filter),
                summary_layer_fields_3: applyDisplayFilterLayerFields(rawLocation.summary_layer_fields_3, filter),
                summary_layer_fields_4: applyDisplayFilterLayerFields(rawLocation.summary_layer_fields_4, filter),
                summary_layer_fields_pdf: applyDisplayFilterLayerFields(rawLocation.summary_layer_fields_pdf, filter),
                summary_min_max_breakdown: applyDisplayFilterFields(rawLocation.summary_min_max_breakdown, filter), // returns min max breakdowns for specific fruit types
                summary_category_charts: applyDisplayFilterFields(rawLocation.summary_category_charts, filter), // returns category charts for specific fruit types
                // Return the first set of settings that matches the fruit filter
                pressure_calibration_settings: applyDisplayFilterFields(rawLocation.pressure_calibration_settings, filter)?.[0] || false, // returns pressure calibration settings for specific fruit types
                meta_form_advance_ripening: applyDisplayFilterFields(rawLocation.meta_form_advance_ripening, filter), // returns form fields
                advance_ripening_classes_form: applyDisplayFilterFields(rawLocation.advance_ripening_classes_form, filter), // returns form fields
                advance_ripening_start_form: applyDisplayFilterFields(rawLocation.advance_ripening_start_form, filter), // returns form fields
                visual_check_defect_form: applyDisplayFilterFields(rawLocation.visual_check_defect_form, filter), // returns form fields
                visual_check_option_form: applyDisplayFilterFields(rawLocation.visual_check_option_form, filter), // returns form fields
                defect_groups: applyDisplayFilterFields(rawLocation.defect_groups, filter),


            };
        } catch (e: any) {
            console.error("rawLocation", rawLocation); // eslint-disable-line no-console
            throw new Error(e);
            // throw new Error("Error filtering location fields.");

        }
    },
    is_location_calibrated(location, layer) {
        const { pressure_calibration_settings } = location;
        const requested_calibration_stage = pressure_calibration_settings?.calibration_stage;
        // console.log("pressure_calibration_settings", pressure_calibration_settings);
        // console.log("requested_calibration_stage", requested_calibration_stage);
        // console.log("layer.calibrated_stages", layer.calibrated_stages);
        return (layer.calibrated_stages || []).some((i) => i.calibration_stage === requested_calibration_stage);
    },
    // not sure if this is good....less verbosity, but also less explicit.
    default_location_config,
    get_stage(stage) {
        return (this.stages.find((i) => i.value === stage) || this.stages[0]) as Stage;
    },

    // * This will get you the config for this layer, with attributes filtered
    get_layer_config(layer) {
        if (!layer?.type) {
            return undefined;
        }

        const rawLayer = this.get_raw_layer_config(layer.type);
        if (!rawLayer) {
            return undefined;
        }
        const filter = { layer_type: layer.type, fruit_type: layer.fruit_type };
        return {
            ...rawLayer,
            meta_display: applyDisplayFilterLayerFields(rawLayer.meta_display, filter),
            meta_form: applyDisplayFilterFields(rawLayer.meta_form, filter),
            show_images: applyDisplayFilterFields(rawLayer.show_images, filter), // not really sure if this is used or not
            special_actions: applyDisplayFilterFields(rawLayer.special_actions, filter),
            show_metric_cards: applyDisplayFilterFields(rawLayer.show_metric_cards, filter),
            show_arrival_check: rawLayer.show_arrival_check ? { ...rawLayer.show_arrival_check, fields: applyDisplayFilterLayerFields(rawLayer.show_arrival_check?.fields, filter) } : rawLayer.show_arrival_check,
            show_children: rawLayer.show_children ? { ...rawLayer.show_children, fields: applyDisplayFilterLayerFields(rawLayer.show_children?.fields, filter) } : rawLayer.show_children,
            show_advance_ripening: rawLayer.show_advance_ripening ? { ...rawLayer.show_advance_ripening, fields: applyDisplayFilterLayerFields(rawLayer.show_advance_ripening?.fields, filter) } : rawLayer.show_advance_ripening,
        };
    },
    // Use this if you just want the raw config for the type:string
    get_raw_layer_config(layer_type) {
        if (!layer_type) {
            return this.layer_types[0] as LayerTypeConfig;
        }
        return (this.layer_types.find((i) => i.value === layer_type) || this.layer_types[0]) as LayerTypeConfig;
    },
    translate_atron_color_to_manual_color_stage(atron_color) {
        if (atron_color === 2 || atron_color === 4) {
            return 3;
        }
        return atron_color;
    },
    // * This will be removed soon, use IDP for calculating categories and storing them in meta
    ripening_stages: [
        {
            gte: false,
            lt: 3,
            label: "RTE-RTE+",
            color: "success",
        },
        {
            gte: 3,
            lt: 6,
            label: "Triggered",
            color: "warning",
        },
        {
            gte: 6,
            lt: false,
            label: "Hard",
            color: "danger",
        },
    ],

    ripening_stages_firmness,
    pressure_classes,

    kg_to_ripening_stage(kg) {
        return value_to_category(kg, this.ripening_stages);
    },
    firmness_to_ripening_stage(median_firmness) {
        return value_to_category(median_firmness, this.ripening_stages_firmness);
    },
    firmness_to_display_value: (firmness) => firmness,
    headerSecondaryLinks: [headerLinks.settings, headerLinks.logout],
    headerMainLinks: [headerLinks.home],
    barcode_filter: (scanned) => scanned,
    use_barcode_scanner: true,
    onchange_callbacks: {
        set_size_category_for_size: ({ setValue, field, value }) => {
            setValue(field, value);
            // For as far as I know they are the same for every tenant
            const size_categories = [
                {
                    gte: 26,
                    lte: 32,
                    label: "S",
                    color: "black",
                },
                {
                    gte: 18,
                    lte: 24,
                    label: "M",
                    color: "black",
                },
                {
                    gte: 8,
                    lte: 16,
                    label: "L",
                    color: "black",
                },

            ];
            const cat = find_category(value, size_categories);
            if (cat) {
                setValue("size_category", cat.label);
            }
        },
        set_harvest_date_for_julian_code: ({ setValue, field, value }) => {
            setValue(field, value);
            setValue("harvest_date", julianCode2packingDate(value));
        },
        set_julian_code_for_harvest_date: ({ setValue, field, value }) => {
            setValue(field, value);
            setValue("julian_code", packingDate2JulianCode(value));
        },
        set_harvest_date_to_for_min_fruit_age: ({ setValue, field, value }) => {
            setValue(field, value);
            setValue("harvest_date_to", fruitAge2Date(value));
        },
        set_harvest_date_from_for_max_fruit_age: ({ setValue, field, value }) => {
            setValue(field, value);
            setValue("harvest_date_from", fruitAge2Date(value));
        },
        set_sample_size_for_fruit_size: ({ setValue, field, value, object }) => {
            setValue(field, value);
            setSampleSize({
                box_weight: object.box_weight,
                box_size: value,
                box_count: object.box_count || 1
            }, setValue);
        },
        set_sample_size_for_box_weight: ({ setValue, field, value, object }) => {
            setValue(field, value);
            setSampleSize({
                box_weight: value,
                box_size: object.box_size,
                box_count: object.box_count || 1
            }, setValue);
        },
        set_mehadrin_nl_sample_size_for_box_weight_and_set_box_count: ({ setValue, field, value, object }) => {
            setValue(field, value);
            let box_count = 1;
            if (value === "4") {
                box_count = 3;
            } else if (value === "10") {
                box_count = 2;
            }
            setValue("box_count", box_count);

            setSampleSize({
                box_weight: value,
                box_size: object.box_size,
                box_count
            }, setValue);
        },
        set_sample_size_for_box_count: ({ setValue, field, value, object }) => {
            setValue(field, value);
            setSampleSize({
                box_weight: object.box_weight,
                box_size: object.box_size,
                box_count: value
            }, setValue);
        },
        set_mission_sample_size_for_fruit_size_avocado: ({ setValue, field, value, object }) => {
            setValue(field, value);
            setMissionSampleSizeAvocado({
                box_weight: object.box_weight,
                box_size: value,
            }, setValue);
        },
        set_mission_sample_size_for_box_weight_avocado: ({ setValue, field, value, object }) => {
            setValue(field, value);
            setMissionSampleSizeAvocado({
                box_weight: value,
                box_size: object.box_size,
            }, setValue);
        },
        set_mission_sample_size_for_fruit_size_mango: ({ setValue, field, value, object }) => {
            setValue(field, value);
            setMissionSampleSizeMango({
                box_count: object.box_count,
                box_size: value,
            }, setValue);
        },
        set_mission_sample_size_for_box_count_mango: ({ setValue, field, value, object }) => {
            setValue(field, value);
            setMissionSampleSizeMango({
                box_count: value,
                box_size: object.box_size,
            }, setValue);
        },
        set_box_weight_net_for_box_weight_gross: ({ setValue, field, value, object }) => {
            setValue(field, value);
            const box_weight_gross = value || 0;
            const box_weight_empty = object.box_weight_empty || 0;
            const box_weight_net = calculateAbsoluteDifference(box_weight_gross, box_weight_empty);

            setValue("box_weight_net", box_weight_net);
        },
        set_box_weight_net_for_box_weight_empty: ({ setValue, field, value, object }) => {
            setValue(field, value);
            const box_weight_empty = value || 0;
            const box_weight_gross = object.box_weight_gross || 0;
            const box_weight_net = calculateAbsoluteDifference(box_weight_gross, box_weight_empty);

            setValue("box_weight_net", box_weight_net);
        },
        set_ripening_cell_sample: ({ setValue, field, value }) => {
            setValue("ripening_sample", `${field} ${value}`);
        }

    },
    get_onchange_callback(callback_ref) {
        return this.onchange_callbacks[callback_ref];

    },
};

export const tenantIds = {
    ExperienceData: "a6aff297-ac1b-4e81-88a6-420591d31f31",
    FruitSolute: "54bb5edb-b95d-4376-b064-1d6de60c1708",
    Bakker: "fbd6b96d-25a4-4898-a042-deab47c2bb5d",
    BakkerBelgium: "c4169992-7b9a-4ac2-9c57-3bbbf0172cf4",
    NaturesPrides: "fd331325-68a3-4129-9cf9-3e92e3799948",
    OmerDecugis: "0f4cb0a5-55c6-4321-8a19-6c0c09019904",
    GeorgesHelfer: "840d4805-f9b6-4e4a-b943-8d4ca54a389a",
    TheAvocadoCompany: "f0616485-c188-4f46-af85-e393641a79b1",
    SpecialFruit: "961bcfeb-0a01-40c0-9314-05f1e9c8b32d",
    FrutosGuadalajara: "add258f0-dd3b-4de7-a499-584c0c2065fe",
    SHPTropical: "f517b5e2-0a6b-4938-b493-57d906a30855",
    TropsSpain: "010f2569-9dc1-41e9-89da-9e170b92e3e8",
    EDMissionBreda: "03c78ada-9b11-4134-977a-cf3e1128e8ff",
    ActualMission: "8e1f271d-c4fc-4d96-9ac2-9344a044e1e6",
    DoleExotics: "f4b8baea-e6cf-4c7e-9e84-f10fdaa21bc0",
    DoleEurope: "a588906b-00ef-46e6-8992-13fe8b1e437b",
    MasterTenant: "master_tenant",
    Agrovision: "894b0d51-3620-4c7e-ae4e-159b176c0b7a",
    FruitifyExperts: "4277aabc-eee1-4325-961a-d60f8570c4a1",
    DeLaat: "2a15ddc0-0aee-4129-b619-9565db2de1d3",
    colruyt: "c0c5d583-1daf-41b8-b2f9-3590cb8a3856",
    EyeOnProducer: "f09bfe69-5ede-4e92-a61c-d070a7b65454",
    GlobalPacific: "7ebcd8e4-9247-4243-80f0-ffd4b41da836",
    Mehadrin_mtexukcom: "a0ef7762-3dac-476f-9b6f-26f37e25cca6",
    Mehadrin_mtexnl: "436bcef8-8436-463b-8946-0e5aa737cd22",
    // * Internal tenants
    repair_shop: "repair-shop",
    master_tenant: "master_tenant",
};


export const DeviceResultActions = {
    ask_pressure: "ask_pressure",
    inline_form: "inline_form",
    setup_pressure_calibration: "setup_pressure_calibration",
    setup_pressure_calibration_with_form: "setup_pressure_calibration_with_form",
    pressure_advice: "pressure_advice",
    calibrate_after_n_fruits: "calibrate_after_n_fruits",
    none: "none"
};
