/** @jsxImportSource @emotion/react */
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { VIEW } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../utils";
import MetaForm from "../../Forms/MetaForm";
import { IS_SET_FILTER_OPTIONS } from "../../Helper/LayerSortableHeader";
import { attributeIsSet } from "../Tenants/Forms/FlagConfigForm";
import { useSwitchTabContext } from "./fields/useSwitchTab";

// * This component can probably be deprecated, but at some point in the near future we want to show layers "grouped" by attribute as in RipeWise,
// * Then this modal gets relevant again, Will leave it for now.
export default function TabSettingsModal() {
    const { updatePersonalTabSettings, activeTab, refreshTab, submitTabQuery } = useSwitchTabContext();
    const [isOpen, setModal] = useState(false);
    const config = useConfig();

    const setViewStrategy = (view_strategy, layer_type) => {
        updatePersonalTabSettings("view_strategy", view_strategy);
        updatePersonalTabSettings("layer_type", layer_type);
    };

    const setValue = (key, value) => updatePersonalTabSettings(key, value);

    const formFields = [
        {
            label: "Max days in facility",
            description: `Only show items that have been in the facility for less then ${(attributeIsSet(activeTab?.max_days_in_facility) ? activeTab?.max_days_in_facility : "this number")} of days.`,
            name: "max_days_in_facility",
            type: "range",
            min: 0,
            max: 365,
            step: 1,
        },
        {
            label: "Max days in stage",
            description: `Only show items that have been in the current stage for less then ${(attributeIsSet(activeTab?.max_days_in_stage) ? activeTab?.max_days_in_stage : "this number")} of days.`,
            name: "max_days_in_stage",
            type: "range",
            min: 0,
            max: 365,
            step: 1,
        },
        {
            label: "Planned work look ahead days",
            description: `Only show items that have planned work within ${(attributeIsSet(activeTab?.planned_work_look_ahead_days) ? activeTab?.planned_work_look_ahead_days : "this numer")} of days. Fill 0 to show work to be done today, negative values for overdue work.`,
            name: "planned_work_look_ahead_days",
            type: "range",
            min: -365,
            max: 365,
            step: 1,
        },
        {
            label: "Show layers with or without planned work?",
            description: "Show layers with or without planned work in the overview table. Use this to only display unplanned work. Or only planned work without specific date range filled baove.",
            type: "radio",
            name: "show_layers_without_planned_work",
            options: IS_SET_FILTER_OPTIONS,
        },
        // Disabled feature for now
        // {
        //     label: "Show layers with draft reports?",
        //     description: "Show layers with draft reports in the overview table. Select the type of draft reports to show.",
        //     name: "show_draft_reports",
        //     type: "single-select",
        //     multi: true,
        //     return_array: true,
        //     options: ["qc_status_advance-ripening", "qc_status_intake"].map((i) => ({ value: i, label: i }))
        // },
        // {
        //     label: "Show layers with sent reports?",
        //     description: "Show layers with sent reports in the overview table. Select the type of sent reports to show.",
        //     name: "show_sent_reports",
        //     type: "single-select",
        //     multi: true,
        //     return_array: true,
        //     options: ["qc_status_advance-ripening", "qc_status_intake"].map((i) => ({ value: i, label: i }))
        // },

    ];

    const display_strategy = activeTab?.view_strategy === VIEW.SELF || !activeTab?.view_strategy ? "" : ` - ${activeTab?.view_strategy}`;
    const submit = () => {
        refreshTab();
        setModal(false);
    };
    const reset = () => {
        updatePersonalTabSettings({});
        submitTabQuery({}); // * Also reset the meta query here, to make sure the query is reset to default
        setModal(false);
    };
    return (
        <div className="pb-2 pb-md-0 pe-2">
            <Button color="light" className="text-nowrap" onClick={() => setModal(true)}>
                <FontAwesomeIcon icon={faCog} /> {activeTab?.layer_type} {display_strategy}
            </Button>
            <Modal isOpen={isOpen} size="xl">
                <ModalHeader >Tab settings</ModalHeader>
                <ModalBody>
                    {getFilledArrayOrDefault(activeTab?.view_strategies).map((i, index) => <div key={index} className="pb-4">
                        <h4 className="text-muted mb-0">{i.title}</h4>
                        <p className="mb-1">{i.description}</p>
                        <Button size="sm" color="primary" outline={activeTab?.layer_type !== i.layer_type || activeTab?.view_strategy !== i.view_strategy} onClick={() => setViewStrategy(i.view_strategy, i.layer_type)}>Select view</Button>
                    </div>)}

                    <MetaForm config={config} meta={formFields} object={activeTab || {}} setValue={setValue} />

                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onClick={() => reset()}>Reset</Button>
                    <Button color="primary" onClick={() => submit()}>Submit</Button>
                    <Button color="light" onClick={() => setModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
