/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { listFacilityLocations } from "../../../../../actions/FacilityLocations/actions";
import {
    addLayerLocationHistory,
    patchLayer
} from "../../../../../actions/Layers/actions";
import { LAYER_STAGE } from "../../../../../actions/Layers/constants";
import { applyDisplayFilterFields } from "../../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../../actions/Tenants/config/configHook";
import MetaForm, { metaFormIsValid } from "../../../../Forms/MetaForm";
import { Loading } from "../../../../Helper/Loading";

// * This component is used to display the CTA form for a single layer from the check summary
export enum CTA_FORM_LOCATION {
    MODAL_AFTER_SUBMIT = "modal_after_submit",
    BELOW_CHECK_SUMMARY = "below_check_summary",
}

const LayerCtaCheckSummary = () => {
    const config = useConfig();
    const params = useParams();

    const [isOpen, toggle] = useState(false);
    const dispatch = useDispatch();
    const locations = useSelector((state:any) => state.facilityLocations.locations);
    const [allocateFormState, setAllocateFormState] = useState({} as any);

    const check = useSelector((state:any) => state.checks.current);
    const layer = useSelector((state:any) => state.layers.current);
    const navigate = useNavigate();
    const location = config.get_location(layer, check);

    const layer_config = config.get_layer_config(layer);
    const meta_form = applyDisplayFilterFields(location?.cta_form, {});
    const normalizeUrl = () => navigate(`/layer/${layer.id}/add-check/${check.test_id}/${params?.action}`);

    useEffect(() => {
        if (layer?.id && params?.modal_type === "cta") {
            toggle(true);
            dispatch(listFacilityLocations({ offset: 0, limit: 200 }) as any);
            setAllocateFormState(layer.latest_location || {});
        } else {
            toggle(false);
        }

    }, [check.test_id, layer.id, params?.modal_type]);

    const setValue = (field, value) => dispatch(patchLayer(layer.id, field, value, false) as any);

    const onDebounce = (field, value) => {
        const field_config = meta_form.find((i) => i.name === field);
        if (field_config && metaFormIsValid([field_config], { [field]: value })) {
            return dispatch(patchLayer(layer.id, field, value, true) as any);
        }
        return Promise.resolve();
    };

    const onDebounceLocation = (field, value) => {
        if (field === "location_id") {
            const location = locations.find((i) => i.location_id === value);
            if (location) {
                const { stage, location_id } = location;
                return dispatch(addLayerLocationHistory({
                    layer_id: layer.id,
                    stage,
                    location_id,
                    include_children: false }) as any);
            }

        }
        if (field === "stage") {
            return dispatch(addLayerLocationHistory({
                layer_id: layer.id,
                [field]: value,
                include_children: false }) as any);
        }
        return Promise.resolve();
    };

    const onSubmit = () => {
        if (meta_form && !metaFormIsValid(meta_form, layer)) return;

        let { layer_id } = check;
        if (layer.parents.length > 0 && check.location === "intake") {
            layer_id = layer.parents[0].id;
        }

        if (check?.sample_label) {
            navigate(`/ripening-room/${check.location_id}/date/${dayjs(check.created).format("DD-MM-YYYY")}`);
        } else {
            navigate(`/layer/${layer_id}`);
        }
        // dispatch(triggerLayerBusinessRules(layer.id) as any);
        // normalizeUrl();
    };

    const onClose = () => normalizeUrl();

    const loading = !layer_config || !layer;
    const allocate_form = [
        {
            name: "stage",
            label: "Stage",
            type: "radio",
            options: Object.keys(LAYER_STAGE).map((i) => ({ value: LAYER_STAGE[i], label: i })),
            onchange_callback: ({ field, value, setValue }) => {
                const location = locations.find((i) => i.location_id === allocateFormState.location_id);
                // unset location, if selected stage is different from the current location
                if (location && location.stage !== value) {
                    setAllocateFormState({ stage: value });
                }
                setValue(field, value);
            }
        },
        // {
        //     name: "location_id",
        //     label: "Location",
        //     type: locations.length > 6 ? "single-select" : "radio",
        //     options: locations.map((i) => ({ ...i, value: i.location_id })),
        //     onchange_callback: ({ field, value, setValue }) => {
        //         const location = locations.find((i) => i.location_id === value);
        //         if (location) {
        //             setAllocateFormState(location);
        //         } else {
        //             setAllocateFormState({});
        //         }
        //     }
        // }
    ];

    const Form = () => {
        if (loading) {
            return null;
        }
        return <>
            <MetaForm
                meta={allocate_form}
                object={allocateFormState}
                config={config}
                setValue={(field, value) => setAllocateFormState({ ...allocateFormState, [field]: value })}
                onDebounce={onDebounceLocation}
            />
            <MetaForm
                meta={meta_form}
                object={layer}
                config={config}
                setValue={setValue}
                onDebounce={onDebounce}
            />
        </>;
    };

    // * Show directly inline in the check summary
    if (location.cta_form_location === CTA_FORM_LOCATION.BELOW_CHECK_SUMMARY) {
        return <div className="pt-3">
            <h5>{location?.cta_form_title} {layer.label}</h5>
            <Form />
        </div>;
    }

    // * Show in a modal after submitting the check, more interupting
    return (
        <Modal isOpen={isOpen} size="md">
            { loading && <Loading /> }
            { !loading && <>
                <ModalHeader toggle={() => () => onClose()} onClick={() => onClose()}>{location?.cta_form_title} {layer.label} </ModalHeader>
                <ModalBody>
                    <Form />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => onClose()}>Cancel</Button>
                    <Button color="success" onClick={() => onSubmit()}>Save</Button>
                </ModalFooter>
            </>}
        </Modal>
    );
};


export default LayerCtaCheckSummary;
