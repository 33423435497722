/** @jsxImportSource @emotion/react */
import { faSend } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    FormGroup, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { patchLayer } from "../../../../actions/Layers/actions";
import { blobToBase64 } from "../../../../utils/base64";
import { QC_STATUS } from "./Report/QCStatusButton";


import { Layer } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import useAvosApi from "../../../../utils/useAvosApiHook";
import { AttachmentLink } from "../../../Helper/AttachmentLink";
import { getBlobNameFromUrl } from "../../Layers/Layer/LayerReportsView";
import { truncateText } from "../../Layers/Layer/Report/utils";
import { getUploadedReports } from "./LayerReportsView";


export interface AttachedUrls {
    [key: string]: string;
}

export interface SendReportRequestBody {
    layer_id: string;
    report_base64: string;
    config_value: string;
    report_file_type: string;
    attached_urls?: AttachedUrls;
}

export interface SendReportButtonProps {
    blob: Blob | undefined;
    layerId: string;
    config_value: string;
    status: string;
}

export const SendReportButton = ({ layerId, blob, config_value, status }: SendReportButtonProps) => {
    const dispatch = useDispatch();

    const [onSendReport] = useAvosApi("/notification/report-to-supplier", "POST", {}, {
        onSuccess: {
            message: "Report sending request received",
            showMessage: true
        }
    }) as any;
    const onSend = async (attached_urls) => {
        // * Update the layer QC status to sent
        dispatch(patchLayer(layerId, `qc_status_${config_value}`, QC_STATUS.SENT, true) as any);

        const base64Data = await blobToBase64(blob);

        onSendReport({
            layer_id: layerId,
            report_base64: base64Data,
            config_value,
            status, // * Actual result of the report success, warning, danger
            report_file_type: "pdf",
            attached_urls,
        });
    };

    const pdfSizeInMB = blob ? blob.size / 1024 / 1024 : 0;
    return <SendReportModal onSend={onSend} config_value={config_value} pdfSizeInMB={pdfSizeInMB} />;
};


const MAX_PDF_SIZE_IN_MB = 8;


interface SendReportModalProps {
    onSend: (attachedUrls?: AttachedUrls) => void;
    config_value: string;
    pdfSizeInMB: number;
    checkPDFSize?: boolean;
}

export const SendReportModal = ({ onSend, config_value, pdfSizeInMB, checkPDFSize }: SendReportModalProps) => {
    const config = useConfig();
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const [attachedUrls, setAttachedUrls] = useState({});
    const uploads = getUploadedReports(layer);
    const layer_config = config.get_layer_config(layer);
    const pdf_config = config.get_pdf_report(layer, config_value);

    const [isOpen, setIsOpen] = useState(false);
    const onSendButtonClick = () => {
        setIsOpen(false);
        onSend(attachedUrls);
    };
    const handleCheckboxChange = (key, index, url) => {
        const blob_name = getBlobNameFromUrl(url);
        setAttachedUrls((prevState) => ({
            ...prevState,
            [`${key}_${index}`]: !prevState[`${key}_${index}`] ? blob_name : undefined
        }));
    };

    const exceedsThePdfSizeLimit = pdfSizeInMB > MAX_PDF_SIZE_IN_MB;
    const isPdfEmpty = pdfSizeInMB === 0;
    const sendReportButtonDisabled = checkPDFSize && (isPdfEmpty || exceedsThePdfSizeLimit);

    // * For every report type that is uploadable I will also assume you can add it as an attachement
    // const attachedReports = Object.entries(uploads).filter(([reportType]) => pdf_config.attachements.include(reportType) );
    const attachedReports = Object.entries(uploads);
    return <>
        <div>{
            checkPDFSize && exceedsThePdfSizeLimit ? <span className="text-warning p-2">The report size exceeds the limit of 8MB</span> : <></>
        }</div>
        <div className="pe-4">
            <Button color="primary" outline className="text-nowrap" disabled={sendReportButtonDisabled || pdfSizeInMB === 0} size="sm" onClick={() => setIsOpen(true)}> Send {pdf_config.text} report <FontAwesomeIcon icon={faSend} /></Button>
        </div>
        <Modal key={1} isOpen={isOpen} size="md">
            <ModalHeader >Send {pdf_config.text} report to the supplier</ModalHeader>
            <ModalBody>
                <Row>{layer_config?.show_upload_reports_button && attachedReports.length > 0 && <p>Which files you want to sent?</p>}</Row>
                <Row>{layer_config?.show_upload_reports_button && attachedReports.length === 0 && <p>No attached files founded</p>}</Row>
                {attachedReports.map(([reportType, reports]) => {
                    return <div key={reportType}>
                        <div>
                            {reports.map(([key, url], index) => {
                                const target = `${key}_${index}`;
                                return (<div key={index} style={{ display: "flex", alignItems: "center" }}>
                                    <FormGroup check style={{ marginRight: "8px" }}>
                                        <Input
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(key.replace(`report_${key}_`, ""), index + 1, url)}
                                        />
                                    </FormGroup>
                                    <div style={{ flex: 1 }}>
                                        <AttachmentLink
                                            url={url}
                                            label={truncateText(key.replace(`report_${key}_`, ""), 35, 40)}
                                            id={target}
                                        />
                                    </div>
                                </div>);
                            })}

                        </div>
                    </div>;
                })}
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setIsOpen(false)}>Cancel</Button>
                <Button color="success" onClick={onSendButtonClick}>Send <FontAwesomeIcon icon={faSend} /></Button>
            </ModalFooter>
        </Modal>
    </>;
};
