/** @jsxImportSource @emotion/react */
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { DATE_URL_FORMAT } from "../../Ripening/RipeningRoom/hooks/useRipeningRoom";
import LocationTracker from "./Components/LocationTracker";

interface LayerBreadcrumbsProps {
    location_config?: {
        text: string;
    };
    check?: {
        group_label: string;
        location_id: string;
        created: string;
    };
}

const LayerBreadcrumbs: React.FC<LayerBreadcrumbsProps> = ({ location_config = false, check = undefined }) => {
    const layer = useSelector((state: any) => state.layers.current);
    const navigate = useNavigate();
    const config = useConfig();
    const layer_config = config.get_layer_config(layer);

    const renderLayerLabel = (label: string) => {
        if (label.length > 12) {
            return `${label.slice(0, 4)}...${label.slice(-4)}`;
        }
        return label;
    };

    if (!layer_config) {
        return null;
    }

    const parent = layer.parents && layer.parents.length > 0 ? layer.parents.at(-1) : false;
    const parent_layer_type = parent ? config.get_raw_layer_config(parent.type) : false;

    return (
        <div className="d-flex justify-content-between">
            <div>
                <Breadcrumb className="mt-3 mb-5">
                    <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/layer`)}>
                        <FontAwesomeIcon icon={faHome} />
                    </BreadcrumbItem>
                    {parent && parent_layer_type && (
                        <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/layer/${parent.id}`)}>
                            {parent_layer_type.text} {renderLayerLabel(parent.label)}
                        </BreadcrumbItem>
                    )}
                    {!check?.group_label && <BreadcrumbItem href="#" active={!location_config} tag={location_config ? "a" : "span"} onClick={() => navigate(`/layer/${layer.id}`)}>
                        <span>{layer_config.text} {renderLayerLabel(layer.label)}</span>
                    </BreadcrumbItem>}
                    {check?.group_label && <BreadcrumbItem href="#" active={!location_config} tag={location_config ? "a" : "span"} onClick={() => navigate(`/ripening-room/${check.location_id}/from/${dayjs(check.created).subtract(5, "day").format(DATE_URL_FORMAT)}/to/${(dayjs(check.created).format(DATE_URL_FORMAT))}`)}>
                        <span>{check.group_label}</span>
                    </BreadcrumbItem>}
                    {typeof location_config === "object" && (
                        <BreadcrumbItem active>
                            <span>{location_config.text} Check</span>
                        </BreadcrumbItem>
                    )}
                </Breadcrumb>
            </div>
            <LocationTracker />
        </div>
    );
};

export default LayerBreadcrumbs;
