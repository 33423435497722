/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../../../actions/Admin/reducer";
import { getReports, updateReportsFilter } from "../../../actions/SupplierPortal/report/actions";
import { Report, ReportState } from "../../../actions/SupplierPortal/report/reducer";
import { Tenant } from "../../../actions/SupplierPortal/tenant/reducer";
import { RootState } from "../../../reducers";
import { AttachmentButton } from "../../Helper/AttachmentLink";
import LayerMetaColorBadgeField from "../../scenes/Layers/fields/LayerMetaColorBadgeField";
import { PageBase, PageBaseTableRowField } from "../Admin/PageBase";
import { formatDateTime } from "../Layers/fields/formatters";

const ReportsPage = () => {
    const dispatch = useDispatch();
    const reportState = useSelector<RootState, ReportState>((state) => state.supplierPortal.report);
    const selectedTenant = useSelector<RootState, Tenant | undefined>((state) => state.supplierPortal.tenant.selected);

    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateReportsFilter(filter));
    };
    const onListEntities = (filter: Filter) => {
        if (selectedTenant) dispatch(getReports(selectedTenant.id, filter));
    };

    // TODO: implement field api here using config coming from FieldConfigForm
    const tableRow: PageBaseTableRowField<Report>[] = [
        {
            label: "Score",
            name: "status",
            lg: 1,
            display_component: (value) => {
                // @fedai what is this doing ?? there is also intake_dates and intake_mango etc etc
                if (value.type === "intake") {
                    // TODO: Temporary fix until passing to configuration solution
                    const badge = new LayerMetaColorBadgeField("", "");
                    badge.value = value.status;
                    return badge.display_component();
                }
                return null;
            }
        },
        {
            label: "Fruit",
            name: "fruit_type",
            lg: 2,
        },
        {
            label: "Country",
            name: "country",
            lg: 2,
        },
        {
            label: "Container No",
            name: "container_no",
            lg: 3,
        },
        // Disabled for now, not sure if it is needed
        // {
        //     label: "Purchase Order",
        //     name: "layer_id",
        //     lg: 2,
        // },
        {
            label: "Date",
            name: "created",
            lg: 3,
            display_component: (value) => formatDateTime(dayjs(value.created)),
        },
        {
            label: "PDF",
            name: "url",
            display_component: (value) => <AttachmentButton url={value.url} icon={faFilePdf} />,
            lg: 1,
        },
    ];

    const createForms = [];
    return <PageBase<Report>
        title="Reports"
        tableRow={tableRow}
        actionsColumnWidth={1}
        createForms={createForms as any}
        state={reportState}
        events={{ onUpdateFilter, onListEntities }}
        createable={false}
    />;
};


export default ReportsPage;


