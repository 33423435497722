/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LAYER_TAB_CATEGORIES } from "../../../actions/Layers/constants";
import { createTenantConfig, patchTenantConfig } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { CONFIG_TYPES } from "../../../actions/Tenants/config/constants";
import { getFilledArrayOrDefault } from "../../../utils";
import { callAvosApi } from "../../../utils/useAvosApiHook";
import MetaForm from "../../Forms/MetaForm";
import { ConfirmationModal } from "../Helper/ConfirmationModal";
import { ModalResult } from "../Helper/Modal";

interface ListTenantConfigProps {
    type: string;
    title: string;
    defaultConfig: object; // Specify a more detailed type if possible
}

const groupConfigsByTabCategory = (configs) => {
    return configs.reduce((grouped, item) => {
        if (item.type === CONFIG_TYPES.STAGE) {
            const tabCategories = item.config?.tab_categories || [LAYER_TAB_CATEGORIES.OPERATION];
            tabCategories.forEach((category) => {
                if (!grouped[category]) {
                    grouped[category] = [];
                }
                grouped[category].push(item);
            });
        }
        return grouped;
    }, {});
};

const preview = (item) => (
    <div className="mb-0 fw-bold">
        {item.config?.text}
        {item.type === CONFIG_TYPES.STAGE ? (
            <> [ {getFilledArrayOrDefault(item.config?.tab_categories, [LAYER_TAB_CATEGORIES.OPERATION]).join(" | ")} <b>::</b> {item.value} ] </>
        ) : (
            <> [{item.value}] </>
        )}
    </div>
);

function ListTenantConfig({ type, title, defaultConfig }: ListTenantConfigProps) {
    const params = useParams();
    const [createModal, setCreateModal] = useState(false);
    const [query, setQuery] = useState({ value: "", note: "", config: { value: "" } });
    const navigate = useNavigate();
    const { tenant_id } = params;
    const dispatch = useDispatch();
    const config = useConfig();
    const [masterTenantConfigs, setMasterTenantConfigs] = useState([]);
    const configs = config.configs.filter((i) => i.type === type).sort((a, b) => a.position - b.position);
    const [selectedItem, selectItem] = useState(false);

    const deactivate = (config_item) => dispatch(patchTenantConfig(tenant_id, config_item.id, "active", false) as any);

    const closeDeactivateModal = useCallback(
        (result: ModalResult) => {
            if (result === ModalResult.Save && selectedItem) {
                deactivate(selectedItem);
            }
            selectItem(false);
        },
        [deactivate, selectedItem]
    );

    const createConfig = () => {
        dispatch(createTenantConfig(tenant_id, {
            type,
            value: query.value,
            note: query.note,
            position: configs.length ? configs[configs.length - 1].position + 10 : 10,
            config: { ...defaultConfig, ...query.config, value: query.value }
        }) as any).then((response) => {
            if (!response.error) {
                navigate(`/tenants/${tenant_id}/config/${response.payload.data.id}`);
            }
        });

    };

    const positionUp = (config_id, currentPosition) => {
        // * cannot move first item up, duh
        if (currentPosition === 0) return;
        dispatch(patchTenantConfig(tenant_id, config_id, "position", currentPosition - 10) as any);
    };

    const positionDown = (config_id, currentPosition) => {
        if (currentPosition + 1 === config.configs.length) return;
        dispatch(patchTenantConfig(tenant_id, config_id, "position", currentPosition + 10) as any);
    };

    const form = [
        {
            label: "Value",
            description: "Identifier for this config. For Intake check location use `intake`, for Pallet layer type use `pallet` etc etc",
            type: "text",
            name: "value",
            onchange_callback: ({ field, value, setValue }) => setValue(field, value.toLowerCase().replace(/[^a-z0-9]/g, "_"))
        },
        {
            label: "Master tenant template",
            description: "Copy config from master tenant. Leave blank to start from scratch or to import a json export later.",
            type: "single-select",
            name: "template",
            options: masterTenantConfigs,
            onchange_callback: ({ field, value, setValue }) => {
                setValue(field, value);
                const selected = masterTenantConfigs.find((i: any) => i.value === value) as any;
                if (selected) {
                    setQuery((query) => ({
                        ...query,
                        [field]: value,
                        note: `Copy from Master tenant. (${selected.note})`,
                        config: { ...defaultConfig, value: query.value, ...selected.config }
                    }));
                } else {
                    // cleared
                    setQuery((query) => ({
                        ...query,
                        [field]: value,
                        note: "",
                        config: { ...defaultConfig, value: query.value }
                    }));
                }
            },
        },
        {
            label: "Note",
            description: "Optional note",
            type: "textarea",
            name: "note",
        }
    ];

    const isCreateDisabled = () => {
        if (!query.value) {
            return true;
        }
        const duplicate = config.configs.find((i) => i.value === query.value && i.type === type);
        return !!duplicate;
    };

    useEffect(() => {
        if (createModal && type) {
            callAvosApi("/tenants/master_tenant/configs/active").then((res) => {
                setMasterTenantConfigs(res.data.filter((i: any) => i.type === type).map((i: any) => ({ label: i.value, value: i.id, note: i.note, config: i.config })));
            });
        }
    }, [createModal, type]);

    // TODO: Implement checkForConfigErrors
    // configs.map((i) => ({ ...i, hasError: checkForConfigErrors(i), hasWarning: false }));

    const groupedConfigs = groupConfigsByTabCategory(configs);
    const nonStageConfigs = configs.filter((item) => item.type !== CONFIG_TYPES.STAGE);


    return (
        <div>
            <FormGroup className="pt-1">
                <div className="d-flex align-items-center mb-3">
                    <Button color="light" className="ms-auto" size="sm" onClick={() => setCreateModal(true)}>
                        Add {title.toLowerCase()} config
                    </Button>
                </div>
                {/* Render STAGE configs grouped by tab_category */}
                {Object.keys(groupedConfigs).map((category, index) => (
                    <Fragment key={category}>
                        <h4 className="pt-3">{category}</h4>
                        {groupedConfigs[category].map((item) => (
                            <div key={item.id} className="border p-3 mb-3" css={css`background: #fcfcfc;`}>
                                <div className="d-flex align-items-center">
                                    {preview(item)}
                                    <Button size="sm" className="ms-auto my-1" disabled={index === 0} color="light" onClick={() => positionUp(item.id, item.position)}>
                                        <FontAwesomeIcon icon={faCaretUp} />
                                    </Button>
                                    <Button size="sm" className="ms-2 my-1" disabled={index === configs.length - 1} color="light" onClick={() => positionDown(item.id, item.position)}>
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    </Button>
                                    <Button color="danger" className="ms-2 my-1" outline size="sm" onClick={() => selectItem(item)}>
                                        Deactivate
                                    </Button>
                                    <Button color="light" className="ms-2 my-1" size="sm" onClick={() => navigate(`/tenants/${tenant_id}/config/${item.id}`)}>Edit</Button>
                                </div>
                            </div>
                        ))}
                    </Fragment>
                ))}
                {/* Render non-STAGE configs */}
                {nonStageConfigs.map((item, index) => (
                    <div key={item.id} className="border p-3 mb-3" css={css`background: #fcfcfc;`}>
                        <div className="d-flex align-items-center">
                            {preview(item)}
                            <Button size="sm" className="ms-auto my-1" disabled={index === 0} color="light" onClick={() => positionUp(item.id, item.position)}>
                                <FontAwesomeIcon icon={faCaretUp} />
                            </Button>
                            <Button size="sm" className="ms-2 my-1" disabled={index === nonStageConfigs.length - 1} color="light" onClick={() => positionDown(item.id, item.position)}>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </Button>
                            <Button color="danger" className="ms-2 my-1" outline size="sm" onClick={() => selectItem(item)}>
                                Deactivate
                            </Button>
                            <Button color="light" className="ms-2 my-1" size="sm" onClick={() => navigate(`/tenants/${tenant_id}/config/${item.id}`)}>Edit</Button>
                        </div>
                    </div>
                ))}
            </FormGroup>

            <ConfirmationModal
                size="md"
                isOpen={selectedItem !== false}
                onClose={closeDeactivateModal}
            >
                Are you sure you want to deactivate this item?
            </ConfirmationModal>

            <Modal size="lg" isOpen={createModal} toggle={() => setCreateModal(false)}>
                <ModalHeader toggle={() => setCreateModal(false)}>Create config</ModalHeader>
                <ModalBody>
                    <MetaForm meta={form} config={config} object={query} setValue={(field, value) => setQuery((query) => ({ ...query, [field]: value }))} />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setCreateModal(false)}>Cancel</Button>
                    <Button color="primary" disabled={isCreateDisabled()} onClick={createConfig}>Create</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ListTenantConfig;
