/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { getFormOptionsLabel } from "../../../../actions/Tenants/config/configHook";
import { ripening_colors } from "../../../../actions/Tenants/config/constants";
import { getFilledArrayOrDefault } from "../../../../utils";
import { toPercentage } from "../../Layers/fields/formatters";
import useRipeningRoom, { formatDate } from "./hooks/useRipeningRoom";


export default function RipeningCellDays() {
    const { firstWeekDay, setFirstWeekDay, weekDates, openCellConditionsForm, today, conditionsMap } = useRipeningRoom();
    const isToday = firstWeekDay.isSame(today.add(28, "day"), "day");
    return (
        <div className="mx-n2" >

            <div className="d-flex justify-content-between pt-0">
                <div className="d-none d-md-flex align-items-center">
                    <Button
                        onClick={() => setFirstWeekDay(firstWeekDay.subtract(1, "day"))}
                        className="mx-2"
                        style={{ height: "40%" }}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>
                </div>
                <div className="d-flex flex-nowrap py-3" css={css`overflow-x: auto;`}>
                    {weekDates.map((date, index) => (
                        <div key={index} className="p-0 px-2">
                            <CellDay
                                selected={date.isSame(today, "day")}
                                index={index}
                                date={date}
                                conditions={conditionsMap?.[formatDate(date)]}
                                onClick={() => openCellConditionsForm(date)}
                            />
                        </div>
                    ))}
                </div>

                <div className={`d-none d-md-flex align-items-center ${(isToday ? "opacity-50" : "")}`}>
                    <Button
                        onClick={() => setFirstWeekDay(firstWeekDay.add(1, "day"))}
                        className="mx-2"
                        disabled={isToday}
                        style={{ height: "40%" }}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                </div>

            </div>
        </div>
    );
}


function CellDay({ selected, index, date, onClick, conditions }) {
    const glowingEffect = selected ? "box-shadow: 0px 0px 8px 8px rgba(19, 165, 95, 0.1);" : "";

    let titleBackgroundClass = "bg-light";
    if (selected) {
        titleBackgroundClass = "text-white";
    }

    // TODO: The classes from fruit_type config
    const classes = [
        { label: "Hard", count: 100 - index * 16, cumulative: 100 - index * 16, color: ripening_colors.Triggered },
        { label: "Triggered", count: 70 + index * 10, cumulative: 100 - index * 16 + 70 + index * 10, color: ripening_colors["Triggered+"] },
        { label: "RTE", count: 66 + index * 6, cumulative: 100 - index * 16 + 70 + index * 10 + 66 + index * 6, color: ripening_colors.RTE },
    ];
    const total = classes.reduce((acc, item) => acc + (item.count || 0), 0);

    return (
        <div
            className="cursor-pointer rounded"
            key={index}
            css={[css`${glowingEffect}`, css`width: 280px;box-shadow: 0px 0px 5px rgba(19, 165, 95, 0.2);`]}
            onClick={onClick}
        >
            <div
                className={`p-2 rounded-top text-center ${titleBackgroundClass}`}
                css={css`background-color: ${selected ? "#13A55F" : "#f8f9fa"};`}
            >
                <span>{formatDate(date)}</span>
            </div>
            <div
                className="mb-1 font-weight-bold p-2 pt-3"
                css={css`min-height: 136px; font-size: 14px; `}
            >
                <div className="w-100 position-relative py-2 mb-2 rounded" css={css`overflow: hidden;`}>
                    {classes.reverse().map((item, index) => <div key={index} className="position-absolute py-2" css={css`left: 0; top: 0; bottom: 0;background-color: ${item.color};right: ${toPercentage(total - item.cumulative, total, 3)}`}> </div>)}

                </div>
                <div className="">

                    {classes.map((item, index) => {
                        return (
                            <div className="d-flex justify-content-between" key={index}>
                                <span>{item.label}:</span>
                                <span className="fw-bold" css={css`color: ${item.color};`} ><span className="pe-2">{item.count || "-"}</span>| <span className="d-inline-block text-end" css={css`min-width: 2rem;`}>{toPercentage(item.count, total)}</span></span>
                            </div>
                        );
                    })}
                </div>
                <div className="px-2">
                    <div className="my-3 border-top">
                    </div>
                </div>
                <div className="pb-3">

                    {conditions?.hasValues ? (
                        <div>
                            {getFilledArrayOrDefault(conditions?.keyLabelMap).map((item) => {
                                return (
                                    <div className="d-flex justify-content-between" key={item.key}>
                                        <span>{item.label}:</span>
                                        <span className={item.class}>{ getFormOptionsLabel(item.key, item.value) || "-"}</span>
                                    </div>
                                );
                            })}

                        </div>
                    ) : (
                        <div className="text-center">-</div>
                    )}
                </div>
            </div>
        </div>
    );
}
