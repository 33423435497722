/** @jsxImportSource @emotion/react */
// TODO: consider replacing this functionality with a color coding scheme in internal data processing
import FilterableConfigList from "./FilterableConfigList";
import FlagConfigForm, { flagOptions } from "./FlagConfigForm";

const MetricCardOptions = [
    {
        value: "number_of_purchase_orders",
        label: "Purchase Orders",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: null,
                lte: 1,
                flag: "success",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "number_of_ggn",
        label: "GGNs",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: null,
                lte: 1,
                flag: "success",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "number_of_sizes",
        label: "Sizes",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: null,
                lte: 1,
                flag: "success",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "number_of_fruit_varieties",
        label: "Fruit Varieties",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: null,
                lte: 1,
                flag: "success",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "age_average",
        label: "Age",
    },
    {
        value: "occupancy_latest",
        label: "Occupancy"
    },
    {
        value: "dry_matter",
        label: "Dry Matter",
    },
    {
        value: "number_of_countries",
        label: "Countries",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: null,
                lte: 1,
                flag: "success",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "number_of_suppliers",
        label: "Suppliers",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: null,
                lte: 1,
                flag: "success",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },

];

const defaultField = {
    field: {
        key: "number_of_purchase_orders",
        label: "Purchase Orders",
        default_flag: "success",
        flags: [],
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};

export default function RipeningRoomMetricCardsConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.key}]</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Key",
            type: "single-select",
            name: "key",
            options: MetricCardOptions,
            onchange_callback: ({ field, value }) => {
                const option = MetricCardOptions.find((i) => i.value === value);
                if (option) {
                    setObject({ ...object, [field]: value, label: option.label, default_flag: option.default_flag, flags: option.flags });
                    return;
                }
                setObject(defaultField);
            },
        },
        {
            label: "Label",
            type: "text",
            name: "label"
        },
        {
            label: "Default flag",
            type: "single-select",
            name: "default_flag",
            options: flagOptions

        },
        {
            type: "element",
            el: <FlagConfigForm
                fields={object.flags || []}
                description="Possible flags for this field"
                name="flags"
                title="Flags"
                setArray={(flags) => setObject({ ...object, flags })} />
        }

    ];
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        useDisplayFilter ={ ["fruit_types", "exclude_fruit_types"]}
        getFieldTitle={getFieldTitle} />;

}


