/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Container, Table } from "reactstrap";
import { getNotifications, updateFilter } from "../../../actions/Mailbox/actions";
import { MailboxState } from "../../../actions/Mailbox/reducer";
import { RootState } from "../../../reducers";
import { AttachmentLink } from "../../Helper/AttachmentLink";
import { Loading } from "../../Helper/Loading";
import { PaginationRow } from "../../Helper/PaginationRow";
import { TableCell, TableHeaderCell } from "../../Helper/Table";
import { MailRecipients } from "../Helpers";
import { formatDateTime } from "../Layers/fields/formatters";


export default function MailboxPage() {
    const { body } = mailboxComponents();
    return <Container className="py-md-5 py-3">
        {body}
    </Container>;

}

export function MailboxTab() {
    const { body } = mailboxComponents();
    return body;
}
const MailStatus = {
    New: "new",
    InProgress: "in_progress",
    Sent: "sent",
    Failed: "failed",
    Retry: "retry"
};

const BadgeColors = {
    [MailStatus.New]: "primary",
    [MailStatus.InProgress]: "warning",
    [MailStatus.Sent]: "success",
    [MailStatus.Failed]: "danger",
};

const getBadgeColor = (status: string) => {
    const color = BadgeColors[status];
    return color || "secondary";
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const StatusBadge = ({ status }) => {
    status = status === MailStatus.Retry ? MailStatus.InProgress : status;
    const badgeColor = getBadgeColor(status);
    const statusToPrint = capitalize(status.replace("_", " "));
    return <Badge color={badgeColor}>{statusToPrint}</Badge>;
};

const mailboxComponents = () => {
    const dispatch = useDispatch();
    const items = useSelector((state: any) => state.mailbox.items);
    const isLoading = useSelector((state: any) => state.mailbox.isLoading);
    const filter = useSelector<RootState, MailboxState["filter"]>((state) => state.mailbox.filter);

    useEffect(() => {
        dispatch(getNotifications(filter));
    }, [filter]);

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(updateFilter({ ...filter, offset }));
        }
    };

    const updateLimit = (limit) => {
        dispatch(updateFilter({ ...filter, limit }));
    };
    const body = <>
        <div className="pt-4 d-flex flex-column" css={css`min-height: 50vh;`} >
            {isLoading && <Loading />}
            {!isLoading
        && <Table size="sm" responsive={true} borderless={true}>
            <thead>
                <tr>
                    <TableHeaderCell>Subject</TableHeaderCell>
                    <TableHeaderCell>Layer</TableHeaderCell>
                    <TableHeaderCell>Supplier</TableHeaderCell>
                    <TableHeaderCell>Report</TableHeaderCell>
                    <TableHeaderCell>Recipients</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Created</TableHeaderCell>
                </tr>
            </thead>
            <tbody>
                {items.results.map((item) => {
                    const { supplier, layer } = item.attributes;
                    return <tr key={item.id}>
                        <TableCell>{item.subject}</TableCell>
                        <TableCell><Link to={`/layer/${layer.id}`}>{layer.label}</Link></TableCell>
                        <TableCell>{supplier.name}</TableCell>
                        <TableCell>
                            {item.attachment_urls.map((url, i) => <AttachmentLink key={i} url={url} />)}
                        </TableCell>
                        <TableCell><MailRecipients recipients={item.recipients} /></TableCell>
                        <TableCell><StatusBadge status={item.status} /></TableCell>
                        <TableCell>{formatDateTime(dayjs(item.created))}</TableCell>
                    </tr>;
                })}
            </tbody>
        </Table>
            }
        </div>
        <PaginationRow results={items.results} totalCount={items.count} filter={filter} onPageChanged={onPageChanged} updateLimit={updateLimit} />
    </>;
    return { body };
};


