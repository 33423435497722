/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getFacilityLocation, listAllocatedLayers, listFacilityLocations, listLocationChecks, listLocationConditions } from "../../../../../actions/FacilityLocations/actions";
import { GROUP_STRATEGY, GROUP_STRATEGY_LABELS, LAYER_TYPE } from "../../../../../actions/Layers/constants";
import { applyDisplayFilterFields } from "../../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../../../utils";


export const LIST_OR_GRID = {
    LIST: "list",
    GRID: "grid"
};


const accentColors = [
    css`border-color: #0d6efd!important;`, // Blue
    css`border-color: #ffc107!important;`, // Yellow
    css`border-color: #6610f2!important;`, // Indigo
    css`border-color: #fd7e14!important;`, // Orange
    css`border-color: #198754!important;`, // Green
    css`border-color: #d63384!important;`, // Pink
    css`border-color: #0dcaf0!important;`, // Cyan
    css`border-color: #dc3545!important;`, // Red
    css`border-color: #20c997!important;`, // Teal
    css`border-color: #6f42c1!important;`, // Purple
];

interface RipeningRoomContextProps {
    refreshConditions: () => void;
    refreshAllocatedLayers: () => void;
    refreshLocationChecks: () => void;
    firstWeekDay: dayjs.Dayjs;
    setFirstWeekDay: (date: dayjs.Dayjs) => void;
    weekDates: dayjs.Dayjs[];
    editDate: dayjs.Dayjs;
    today: dayjs.Dayjs;
    openCellConditionsForm: (date: dayjs.Dayjs) => void;
    closeCellConditionsForm: () => void;
    conditionsMap: Record<string, { keyLabelMap: KeyLabelMapItem[], hasValues: boolean }>;
    groupView: string;
    setGroupView: (group: any) => void;
    layers: any[]; // * allocated layers in given timeframe
    groups: any[]; // * Found groups in allocated layers in given timeframe
    pallets: any[]; // * Found pallets in allocated layers in given timeframe, with attached group details
    listOrGrid: string;
    setListOrGrid: React.Dispatch<React.SetStateAction<string>>;
    filterLevel: string | null;
    setFilterLevel: React.Dispatch<React.SetStateAction<string | null>>;
    cellLevels: string[];
    cellRows: string[];
    cellColumns: string[];
    selectedLayers: Record<string, boolean>;
    setSelectedLayers: React.Dispatch<React.SetStateAction<object>>;
    selectedLayersArray: string[];
    deselectAll: () => void;
    groupAccentColors: Record<string, any>;
    fruit_type: string | null;
    fruit_type_config?: any;
    currentLocation: any;
    cellConditionsFormFields: any[];

}

const RipeningRoomContext = createContext({
    currentLocation: null,
    refreshConditions: () => null,
    refreshAllocatedLayers: () => null,
    refreshLocationChecks: () => null,
    firstWeekDay: dayjs(),
    setFirstWeekDay: () => null,
    weekDates: [],
    editDate: dayjs(),
    today: dayjs(),
    openCellConditionsForm: () => null,
    closeCellConditionsForm: () => null,
    conditionsMap: {} as Record<string, { keyLabelMap: KeyLabelMapItem[], hasValues: boolean }>,
    groupView: GROUP_STRATEGY.PURCHASE_ORDER,
    setGroupView: () => null,
    layers: [],
    groups: [],
    pallets: [],
    listOrGrid: LIST_OR_GRID.LIST,
    setListOrGrid: () => null,
    filterLevel: null,
    setFilterLevel: () => null,
    cellLevels: [],
    cellRows: [],
    cellColumns: [],
    selectedLayers: {},
    setSelectedLayers: () => null,
    deselectAll: () => null,
    selectedLayersArray: [],
    groupAccentColors: {},
    fruit_type: null,
    cellConditionsFormFields: []
} as RipeningRoomContextProps);


dayjs.extend(customParseFormat);


// Get the dates for the week
const getWeekDates = (firstWeekDay) => {
    const yesterday = firstWeekDay.subtract(1, "day");
    return Array.from({ length: 6 }, (_, i) => yesterday.add(i, "day"));
};


export const DATE_URL_FORMAT = "YYYY-MM-DD";
const DATE_QUERY_FORMAT = "YYYY-MM-DD";
const DATE_DISPLAY_FORMAT = "DD MMM YYYY";

export default function useRipeningRoom() {
    return useContext(RipeningRoomContext);
}

// RipeningCellDays component for navigating through ripening days
export function RipeningRoomProvider({ children }) {
    const today = dayjs();
    const dispatch = useDispatch();
    const params = useParams();
    const [groupView, setGroupView] = useState(GROUP_STRATEGY.PURCHASE_ORDER);
    const [firstWeekDay, setFirstWeekDay] = useState(params.to_date ? dayjs(params.to_date, DATE_URL_FORMAT) : today);
    const [weekDates, setWeekDates] = useState(getWeekDates(today));
    const navigate = useNavigate();
    const config = useConfig();
    const currentLocation = useSelector((state: any) => state.facilityLocations.current);
    const { location_id } = params;


    // * Fysical Location Setup
    const [listOrGrid, setListOrGrid] = useState(LIST_OR_GRID.LIST);
    const [filterLevel, setFilterLevel] = useState<string | null>(null);
    const cellLevels = getFilledArrayOrDefault((config.rooot?.ripening_room_levels || "").split(",").map((i) => i.trim()).filter((i) => i), ["Level 0", "Level 1", "Level 2"]);
    const cellColumns = getFilledArrayOrDefault((config.rooot?.ripening_room_columns || "").split(",").map((i) => i.trim()).filter((i) => i), ["A", "hall", "B"]);
    const cellRows = getFilledArrayOrDefault((config.rooot?.ripening_room_rows || "").split(",").map((i) => i.trim()).filter((i) => i), ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]);

    // * Group Strategy setup
    const location_config = config.get_location({}, { location: "ripening" });
    const default_group_strategy = location_config?.group_strategy;
    useEffect(() => {
        if (default_group_strategy) {
            setGroupView(default_group_strategy);
        }
    }, [default_group_strategy]);

    // * Fetch this and other locations
    useEffect(() => {
        dispatch(getFacilityLocation(location_id));
        dispatch(listFacilityLocations({ offset: 0, limit: 100 }));
    }, [location_id]);

    // * Date navigator setup
    const editDate = dayjs(params.edit_date, DATE_URL_FORMAT);

    // Nicely formatted date for in the URL
    const UrlFromDate = weekDates[0].format(DATE_URL_FORMAT);
    const UrlToDate = firstWeekDay.format(DATE_URL_FORMAT);

    // Load date for the week + 1 day before and after for better scrolling experience
    const queryFromDate = weekDates[0].subtract(1, "day").format(DATE_QUERY_FORMAT);
    const queryToDate = firstWeekDay.add(1, "day").format(DATE_QUERY_FORMAT);

    // * Util function to fetch layers, checks and conditions in timeframe
    const refreshAllocatedLayers = useCallback(
        () => (location_id ? dispatch(listAllocatedLayers(location_id, queryFromDate, queryToDate) as any) : Promise.resolve()),
        [location_id, queryFromDate, queryToDate]
    );
    const refreshLocationChecks = useCallback(
        () => (location_id ? dispatch(listLocationChecks(location_id, queryFromDate, queryToDate) as any) : Promise.resolve()),
        [location_id, queryFromDate, queryToDate]
    );

    const refreshConditions = useCallback(() => {
        // * Fetch conditions also for the previous week to enable the carryover function.
        dispatch(listLocationConditions(location_id, weekDates[0].subtract(5, "day").format(DATE_QUERY_FORMAT), queryToDate) as any);
    }, [location_id, weekDates[0], queryToDate]);


    const openCellConditionsForm = useCallback((date) => navigate(`/ripening-room/${location_id}/from/${UrlFromDate}/to/${UrlToDate}/edit/${date.format(DATE_URL_FORMAT)}`), [location_id, UrlFromDate, UrlToDate]);
    const closeCellConditionsForm = useCallback(() => navigate(`/ripening-room/${location_id}/from/${UrlFromDate}/to/${UrlToDate}`), [location_id, UrlFromDate, UrlToDate]);


    useEffect(() => {
        if (params.edit_date) {
            navigate(`/ripening-room/${location_id}/from/${UrlFromDate}/to/${UrlToDate}/edit/${params.edit_date}`);
        } else {
            navigate(`/ripening-room/${location_id}/from/${UrlFromDate}/to/${UrlToDate}`);
        }
    }, [queryFromDate, queryToDate, params.edit_date]);

    // * Fetch data for the week
    useEffect(() => {
        const weekDates = getWeekDates(firstWeekDay);
        setWeekDates(weekDates);
        const queryFromDate = weekDates[0].subtract(1, "day").format(DATE_QUERY_FORMAT);
        const queryToDate = weekDates[weekDates.length - 1].add(1, "day").format(DATE_QUERY_FORMAT);

        if (!location_id) return;
        dispatch(listAllocatedLayers(location_id, queryFromDate, queryToDate) as any);
        dispatch(listLocationChecks(location_id, queryFromDate, queryToDate) as any);
        //* Fetch conditions also for the previous week to enable the carryover function.
        dispatch(listLocationConditions(location_id, weekDates[0].subtract(6, "day").format(DATE_QUERY_FORMAT), queryToDate) as any);

    }, [location_id, firstWeekDay]);


    // * Get table items for the selected group strategy
    const { layers, groups, pallets } = getRipeningCellPalletGroups(groupView);

    const groupAccentColors = groups.reduce((acc, group, index) => {
        const first_pallet = group[0];
        acc[first_pallet.group.group_query] = accentColors[index % accentColors.length];
        return acc;
    }, {});

    // * Assume all pallets have the same fruit type
    const fruit_type = pallets.find((p) => p.fruit_type)?.fruit_type;
    const fruit_type_config = config.get_fruit_type(fruit_type);
    const cellConditionsFormFields = applyDisplayFilterFields(config?.root_config?.ripening_room_conditions_form, { fruit_type });
    const conditionsMap = getDateConditionsMap(weekDates, cellConditionsFormFields);

    // * State for selected layers
    const [selectedLayers, setSelectedLayers] = useState({});
    const selectedLayersArray = Object.keys(selectedLayers).filter((key) => selectedLayers[key]);
    const deselectAll = () => setSelectedLayers({});


    return <RipeningRoomContext.Provider value={{
        refreshConditions,
        currentLocation,
        refreshAllocatedLayers,
        refreshLocationChecks,
        firstWeekDay,
        setFirstWeekDay,
        weekDates,
        editDate,
        today,
        openCellConditionsForm,
        closeCellConditionsForm,
        conditionsMap,
        groupView,
        setGroupView,
        layers,
        groups,
        pallets,
        listOrGrid,
        setListOrGrid,
        filterLevel,
        setFilterLevel,
        cellLevels,
        cellRows,
        cellColumns,
        selectedLayers,
        setSelectedLayers,
        selectedLayersArray,
        deselectAll,
        groupAccentColors,
        fruit_type,
        fruit_type_config,
        cellConditionsFormFields,

    }} >
        {children}
    </RipeningRoomContext.Provider>;
}


export function getDayConditions(date, formFields) {
    const conditions = useSelector((state: any) => state.facilityLocations.conditions);

    const filteredConditions = conditions.filter(
        (cond) => formatDate(dayjs(cond.active_since)) === formatDate(date)
    ).sort((a, b) => dayjs(b.created).diff(dayjs(a.created)));

    // TODO: make list condifurable if we want to keep
    const keyLabelMap = formFields.map((i) => ({ key: i.name, label: i.label, carryover: i.name !== "ethylene" })).map((item) => {
        const condition = filteredConditions.find((cond) => cond.key === item.key);
        return { ...item, value: condition ? condition.value : undefined };
    });
    return { filteredConditions, keyLabelMap };

}


type KeyLabelMapItem = {
    key: string;
    label: string;
    carryover: boolean;
    value?: any;
    class: string;
};


// This function will return a map of conditions for each day in the week
// It will also carryover settings from previous days if no settings are set for the current day
export function getDateConditionsMap(weekDates: dayjs.Dayjs[], formFields) {
    const conditions = useSelector((state: any) => state.facilityLocations.conditions);

    // Sort conditions once by their created date and convert `active_since` to `dayjs` object
    const sortedConditions = conditions
        .sort((a, b) => dayjs(b.created).diff(dayjs(a.created)))
        .map((cond) => ({ ...cond, active_since: dayjs(cond.active_since) }));

    // Get unique dates from conditions and week dates
    const uniqueDates = [...sortedConditions.map((i) => i.active_since), ...weekDates]
        .sort((a, b) => a.diff(b))
        .reduce((acc, date) => {
            const formattedDate = formatDate(date);
            acc[formattedDate] = true;
            return acc;
        }, {} as Record<string, boolean>);

    let lastFilledKeyLabelMap: KeyLabelMapItem[] | null = null;

    // Build the dateConditionsMap set for each day
    const dateConditionsMap = Object.keys(uniqueDates).map((currentDateStr) => {
        // Filter conditions for the current date
        const filteredConditions = sortedConditions.filter(
            (cond) => formatDate(cond.active_since) === currentDateStr
        );

        const keyLabelMap: KeyLabelMapItem[] = formFields.map((i) => ({ key: i.name, label: i.label, carryover: i.name !== "ethylene" })).map((item) => {
            const condition = filteredConditions.find((cond) => cond.key === item.key);
            return { ...item, value: condition ? condition.value : undefined };
        });

        // Apply carryover logic
        const carriedOverKeyLabelMap = keyLabelMap.map((item) => {
            if (item.value) {
                return { ...item, value: item.value, class: "fw-bold" };
            }
            // Carryover from previous day(s) if applicable
            if (item.carryover && lastFilledKeyLabelMap) {
                const prevItem = lastFilledKeyLabelMap.find((prev) => prev.key === item.key);
                if (prevItem?.value) {
                    return { ...item, value: prevItem.value, class: "fw-light" };
                }
            }

            return item;
        });

        // Update lastFilledKeyLabelMap if the current day has values
        if (carriedOverKeyLabelMap.some((x) => x.value)) {
            lastFilledKeyLabelMap = carriedOverKeyLabelMap;
        }

        return { date: currentDateStr, keyLabelMap: carriedOverKeyLabelMap };
    });

    // Convert the array to a map object with date strings as keys
    return dateConditionsMap.reduce((acc, { date, keyLabelMap }) => {
        acc[date] = { keyLabelMap, hasValues: keyLabelMap.some((x) => x.value) };
        return acc;
    }, {} as Record<string, { keyLabelMap: KeyLabelMapItem[], hasValues: boolean }>);
}

// Utility to format date as 'DD MMM YYYY'
export const formatDate = (date) => (date ? date.format(DATE_DISPLAY_FORMAT) : "");


// * Return a list of groups including pallets, and a list of pallets contain settings for setting up the group
export function getRipeningCellPalletGroups(groupView): { layers: any[], groups: any[], pallets: any[] } {
    const layers = useSelector<any, any>((state) => state.facilityLocations.layers);
    const pallets = getRipeningCellPallets(groupView, layers);
    const groupsObject = pallets.reduce((acc, pallet) => {
        const key = pallet.group ? pallet.group.group_query : "pallet";
        if (!acc[key]) {
            return { ...acc, [key]: [pallet] };
        }
        return { ...acc, [key]: [...acc[key], pallet] };
    }, {});
    const groups = Object.values(groupsObject);

    return { layers, groups, pallets };
}

export function isItemAllocatedOnDateHook() {
    const allocatedLayersPerDay = useSelector<any, any>((state) => state.facilityLocations.allocatedLayersPerDay);
    return useCallback((layer_id, date) => {

        const allocatedLayers = allocatedLayersPerDay.find((day) => dayjs(day.date).format(DATE_URL_FORMAT) === date.format(DATE_URL_FORMAT));
        if (!allocatedLayers) {
            return false;
        }
        return allocatedLayers.layer_ids.includes(layer_id);

    }, [allocatedLayersPerDay]);
}

export function getAllocatedLayersHook() {
    const allocatedLayersPerDay = useSelector<any, any>((state) => state.facilityLocations.allocatedLayersPerDay);
    const layers = useSelector<any, any>((state) => state.facilityLocations.layers);
    return useCallback((date, layer_type: string | null = null) => {
        const allocatedLayers = allocatedLayersPerDay.find((day) => dayjs(day.date).format(DATE_URL_FORMAT) === date.format(DATE_URL_FORMAT));
        if (!layer_type) {
            return allocatedLayers;
        }
        const layer_ids = layers.filter((layer) => layer.type === layer_type).map((layer) => layer.id);
        return allocatedLayers ? allocatedLayers.layer_ids.filter((id) => layer_ids.includes(id)) : [];
    }, [allocatedLayersPerDay, layers]);
}


const getRipeningCellPallets = (group_strategy: string, layers: any) => {
    const pallets = layers.filter((layer) => layer.type === LAYER_TYPE.PALLET);

    const getParent = (p) => p.parents?.[0] || { id: "orphan", label: "Orphan" }; // * If the pallet has no parent, it is an orphan)

    // iF tenants only allows checks per pallet, there is no group possebiltiy
    if (group_strategy === GROUP_STRATEGY.PALLET) {
        return pallets.map((p) => ({
            ...p,
            group: false,
            parent_id: getParent(p).id
        }));
    }

    // iF tenants only allows checks per PO or Pallet, the PO is the group
    if (group_strategy === GROUP_STRATEGY.PURCHASE_ORDER) {
        return pallets.map((p) => ({
            ...p,
            parent_id: getParent(p).id,
            group: {
                group_label: `${GROUP_STRATEGY_LABELS[GROUP_STRATEGY.PURCHASE_ORDER]} ${getParent(p).label}`,
                group_query: `${group_strategy}=${getParent(p).id}`,
                group_strategy
            }
        }));

    }

    return pallets.map((p) => ({
        ...p,
        parent_id: getParent(p).id,
        group: {
            group_label: `${GROUP_STRATEGY_LABELS[group_strategy]} ${p[group_strategy] || "N/A"}`,
            group_query: `${getParent(p).id} ${group_strategy}=${p[group_strategy] || "N/A"}`,
            group_strategy
        }
    }));
};


