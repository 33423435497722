import { Text, View } from "@react-pdf/renderer";
import { doFilterDefects, TenantDefect } from "../../../../Forms/useManagedOptionsHook";
import { toPercentage } from "../../fields/formatters";
import { styles } from "./PDFCheckFruitTables";
import { truncateText } from "./utils";

interface Defect {
    defect_id: string;
    frequency: number;
}
interface Avocado {
    defects: Defect[];
    avocado_id: number;
}
interface Check {
    defect_list: Defect[];
    avocados: Avocado[];
    sample_size: number;
}
interface DefectGroup {
    defect_position: string[];
    defect_severity: string[];
    total_defects_title: string;
    decimals?: number;
    denominator?: string;
}

interface PDFContainerDefectsProps {
    defect_group: DefectGroup;
    all_defects: TenantDefect[];
    printChecks: Check[];
}

const PDFContainerDefects: React.FC<PDFContainerDefectsProps> = ({ defect_group, all_defects, printChecks }) => {
    if (!all_defects) return null;
    const group_defects = doFilterDefects({ defect_position: defect_group.defect_position, defect_severity: defect_group.defect_severity }, all_defects);
    const registered_defects = printChecks.flatMap((check) => [...check.defect_list, ...check.avocados.flatMap((avocado) => avocado.defects)]);
    const total_defects_per_type = registered_defects.reduce((acc, i) => ({ ...acc, [i.defect_id]: (acc[i.defect_id] || 0) + i.frequency }), {});
    const group_defect_ids = group_defects.map((i) => i.defect_id);

    // Count the number of fruit having at least one defect from this group
    const defectedFruit = Object.keys(printChecks.flatMap((check) => check.avocados.filter((avocado) => avocado.defects.filter((i) => i.frequency > 0 && group_defect_ids.includes(i.defect_id)).length > 0))
        .reduce((acc, fruit) => ({ ...acc, [fruit.avocado_id]: true }), {})).length;
    const sum_of_all_defects = group_defect_ids.reduce((acc, defect_id) => acc + (total_defects_per_type[defect_id] || 0), 0);

    // Sum all sample sizes and count all avocados
    const sample_size_sum = printChecks.reduce((acc, check) => acc + Number(check.sample_size), 0);
    const avos_fruit_sum = printChecks.reduce((acc, check) => acc + check.avocados.length, 0);


    // Choose denominator and defects sum
    const denominator = defect_group.denominator === "avos_fruit" ? avos_fruit_sum : sample_size_sum;
    const total_defects = defect_group.denominator === "avos_fruit" ? defectedFruit : sum_of_all_defects;


    // Calculate values based on defect_group
    // const { fruit_defect_summary, denominator, total_defects, decimals } = calculateBasedOnDefectGroup(printChecks, defect_group);
    const decimals = defect_group.decimals || 1;
    const defect_percentage = (value) => toPercentage(value, denominator, decimals);
    const display_defects = group_defects.filter((i) => {
        const numerator = total_defects_per_type[i.defect_id] || 0; // Use 0 if undefined
        const denominatorValue = denominator;

        // Calculate percentage
        if (denominatorValue && denominatorValue > 0) {
            const percentage = (numerator / denominatorValue) * 100;
            // Round the percentage to the specified decimals
            const roundedPercentage = Math.round(percentage * 10 ** decimals) / 10 ** decimals;

            return roundedPercentage > 0; // Filter out 0% values
        }

        return false; // Exclude if denominator is not valid
    });

    return (
        <View style={[styles.metaContainer, styles.horizontalMargins]}>
            <View>
                <View style={styles.metaItems}>
                    <View>
                        <Text style={{ fontSize: "9px" }}>{defect_group.total_defects_title}: </Text>
                    </View>
                    <View style={styles.metaTextValueIntakeReport}>
                        <Text>{defect_percentage(total_defects)}</Text>
                    </View>
                </View>
                <View style={styles.divider}></View>
                {display_defects
                    .map((defect, index) => (
                        <View key={index} style={styles.metaItems}>
                            <View>
                                <Text style={{ fontSize: "9px" }}>{truncateText(defect.label, 28, 26)}:</Text>
                            </View>
                            <View>
                                <Text>{toPercentage(total_defects_per_type[defect.defect_id], denominator, decimals)}</Text>
                            </View>
                        </View>
                    ))}
            </View>
        </View>
    );
};


export default PDFContainerDefects;
