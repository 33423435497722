/** @jsxImportSource @emotion/react */
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Breadcrumb, BreadcrumbItem
} from "reactstrap";
import { addBrowseHistory } from "../../../actions/Navigation/actions";


const RipeningRoomBreadcrumbs = ({ currentLocation }: { currentLocation: any }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { location_id } = useParams();

    useEffect(() => {
        if (currentLocation?.location_id && location) {
            dispatch(addBrowseHistory({ pathname: location.pathname, id: currentLocation?.location_id, label: currentLocation?.label, level: 1 }));
        }
    }, [currentLocation, location]);


    return <Breadcrumb className="my-3">
        <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/layer`)}>
            <FontAwesomeIcon icon={faHome} />
        </BreadcrumbItem>
        {!currentLocation && <BreadcrumbItem href="#" tag="span" >Rooms</BreadcrumbItem>}
        {currentLocation && <BreadcrumbItem href="#" tag="a" onClick={() => navigate(`/ripening-room`)}>Rooms</BreadcrumbItem>}
        {currentLocation && currentLocation?.location_id === location_id && <BreadcrumbItem active={true} tag="span" onClick={() => navigate(`/ripening-room/${currentLocation.location_id}`)}>
            <span>{currentLocation.label}</span>
        </BreadcrumbItem>}
    </Breadcrumb>;

};

export default RipeningRoomBreadcrumbs;
