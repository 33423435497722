/** @jsxImportSource @emotion/react */
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { FruitConfig } from "../../../../actions/Tenants/config/fruitTypes";
import MetaForm from "../../../Forms/MetaForm";
import CategoryConfigForm from "./CategoryConfigForm";
import { SingleFieldForm } from "./FieldConfigForm";
import OptionsConfigForm from "./OptionsConfigForm";

export const defaultFruitType = {
    text: "Avocado",
    value: "avocado",
    a_fruit: "an avocado",
    n: "avocados",
    n_cap: "Avocados",
    external_colors: [],
    internal_colors: [],
    firmness_class: [],
    default_variety: ""
} as FruitConfig;


export default function FruitTypeConfigForm({ config_data, setConfigData }) {
    const config = useConfig();
    const params = useParams();
    const { tenant_id } = params;

    const buttons = !config_data.value ? [] : [
        {
            label: `Selectable Fruit types`,
            name: `fruit_type`,
        },
        {
            label: `Defects internal (Deprecated)`,
            name: `defects_${config_data.value}_internal`,
        },
        {
            label: `Defects external (Deprecated)`,
            name: `defects_${config_data.value}_external`
        },
        {
            label: `Taste`,
            name: `manual_taste_${config_data.value}`
        },
        {
            label: `Maturity`,
            name: `manual_maturity_${config_data.value}`
        },
        {
            label: `PLU`,
            name: `plu_${config_data.value}`
        },
        {
            label: `Flavour nuttiness`,
            name: `flavour_nuttiness_${config_data.value}`
        },
        {
            label: `Flavour creamy`,
            name: `flavour_creamy_${config_data.value}`
        },
        {
            label: "Mouth ripeness",
            name: `mouth_ripeness_${config_data.value}`
        }
    ];

    const form = [
        {
            label: "Value",
            description: "What is the slug or identifier of this fruit type. Used in the database and in urls. Please use singular form with lower case letters (e.g. apple, avocado, cherry, plum).",
            name: "value",
            createable: true,
            type: "text",
            onchange_callback: ({ value }) => {
                setConfigData({ ...config_data,
                    value: value.toLowerCase().replace(/ /g, "_").trim(),
                    text: value.charAt(0).toUpperCase() + value.slice(1),
                    a_fruit: value.startsWith("a") ? `an ${value}` : `a ${value}`,
                    n: value.endsWith("s") ? `${value}es` : `${value}s`,
                    n_cap: value.endsWith("s") ? `${value.charAt(0).toUpperCase()}${value.slice(1)}es` : `${value.charAt(0).toUpperCase()}${value.slice(1)}s`

                });
            }
        },
        {
            label: "Label",
            description: "What is the label of this fruit type. Used in the button.",
            name: "text",
            type: "text"
        },
        {
            label: "A fruit",
            name: "a_fruit",
            type: "text",
            description: "What is the singular form of this fruit type. (e.g. an avocado, a mango, a kiwi)",
        },
        {
            label: "N fruit",
            name: "n",
            type: "text",
            description: "What is the plural form of this fruit type. (e.g. avocados, mangos, cherries)",
        },
        {
            label: "N cap",
            name: "n_cap",
            type: "text",
            description: "What is the plural form of this fruit type with a capital. (Avocados, Mangos, Cherries)",
        },
        {
            type: "element",
            el: <OptionsConfigForm
                fields={config_data.external_colors || []}
                description="Configure radio button for external colors"
                useDisplayFilter={false}
                name="external_colors"
                title="External colors"
                setArray={(external_colors) => setConfigData({ ...config_data, external_colors })} />
        },
        {
            type: "element",
            el: <OptionsConfigForm
                fields={config_data.internal_colors || []}
                description="Configure radio button for internal colors"
                name="internal_colors"
                useDisplayFilter={false}
                title="Internal colors"
                setArray={(internal_colors) => setConfigData({ ...config_data, internal_colors })} />
        },
        {
            label: "Field",
            type: "element",
            name: "field",
            el: <SingleFieldForm
                title="Field for ripening graph"
                setField={(ripening_field) => setConfigData({ ...config_data, ripening_field })}
                field={config_data.ripening_field} />,
        },
        {
            label: "Reverse Ripening Axis?",
            name: "higher_value_is_more_ripe",
            description: "Is the ripening axis reversed? Default is no. (e.g. Firmness is lower when ripe)",
            type: "radio",
            options: [
                { label: "No, lower value is more ripe (e.g. Firmness)", value: false },
                { label: "Yes, higher value is more ripe (e.g. Maturity)", value: true }
            ]
        },
        {
            type: "element",
            el: <CategoryConfigForm
                fields={config_data.firmness_class || []}
                description="Configure categories for firmness class"
                name="firmnes_class"
                useDisplayFilter={false}
                title="Firmness class"
                setArray={(firmnes_class) => setConfigData({ ...config_data, firmnes_class })} />
        },
        {
            type: "header",
            label: "Radio and Dropdown options"
        },
        {
            type: "element",
            el: <div>
                {buttons.map((i, indexx) => <Button key={indexx} color="light" className="me-2 mb-2" size="sm" onClick={() => window.open(`/tenants/${tenant_id}/form-field-options/${i.name}`)}> {i.label} <FontAwesomeIcon icon={faArrowUpRightFromSquare}/></Button>)}
                <Button color="light" className="me-2 mb-2" size="sm" onClick={() => window.open(`/tenants/${tenant_id}/defects/${config_data.value}`)}> Defects <FontAwesomeIcon icon={faArrowUpRightFromSquare}/></Button>
            </div>
        },
    ];

    return <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />;
}
