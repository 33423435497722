/** @jsxImportSource @emotion/react */
import { faDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { listFacilityLocations } from "../../../../actions/FacilityLocations/actions";
import { addLayerLocationHistory, getLayer, getLayerLocationHistory } from "../../../../actions/Layers/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { RadioButtons } from "../../../Forms/RadioButton";

export default function AllocateLayerModal() {
    const config = useConfig();
    const layer = useSelector((state) => state.layers.current);

    const [isOpen, setModal] = useState(false);

    const locations = useSelector((state) => state.facilityLocations.locations);

    // * use value instead of location_id for locations so that the RadioButtons component can use it
    const transformedLocations = locations.map((cell) => ({
        ...cell,
        value: cell.location_id
    }));


    useEffect(() => {
        dispatch(listFacilityLocations({ limit: 25, offset: 0 }));
    }, []);


    const current_location_id = layer.latest_location?.location_id;

    const dispatch = useDispatch();

    const allocateLayerToLocation = (value) => {
        const location = locations.find((cell) => cell.location_id === value);

        if (!location) {
            return;
        }

        const { stage, location_id } = location;

        if (location_id === layer.latest_location?.location_id || !value) {
            return;
        }

        dispatch(addLayerLocationHistory({ layer_id: layer.id, stage, location_id, include_children: true }))
            .then(() => {
                dispatch(getLayer(layer.id));
                dispatch(getLayerLocationHistory(layer.id));
            });
        setModal(false);
    };
    const layer_type = config.get_layer_config(layer);

    return <>
        {
            layer_type.show_allocate_button && <div key={0} className="pe-2">
                <Button
                    size="sm"
                    color="primary"
                    outline
                    className="text-nowrap mb-2"
                    onClick={() => setModal(true)}>Allocate <FontAwesomeIcon icon={faDownToLine} /></Button>
            </div>
        }
        <Modal key={1} isOpen={isOpen} size="md">
            <ModalHeader >Allocation</ModalHeader>
            <ModalBody>
                <RadioButtons
                    color="secondary"
                    current={current_location_id}
                    options={transformedLocations}
                    onChange={(_, value) => allocateLayerToLocation(value)}></RadioButtons>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </>;
}
