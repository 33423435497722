import { useRoutes } from "react-router-dom";
import { getSupplierPortalUser } from "../actions/Auth/actions";
import NoMatch404 from "./NoMatch";
import Check from "./scenes/AddItem";
import AdminPage from "./scenes/Admin/AdminPage";
import Login from "./scenes/Auth/Login";
import Logout from "./scenes/Auth/Logout";
import { RequireAuth } from "./scenes/Auth/RequireAuth";
import DeviceManagment from "./scenes/DeviceManagment";
import DeviceConfiguration from "./scenes/DeviceManagment/DeviceManagement";
import LabellingStart from "./scenes/Labelling";
import LabellingManualSession from "./scenes/Labelling/ManualLabelSession";
import LabellingNewSession from "./scenes/Labelling/NewLabelling";
import LayerIndex from "./scenes/Layers/Layer/LayerIndex";
import PDFCheckReport from "./scenes/Layers/Layer/Report/PDFCheckReport";
import PDFLayerReport from "./scenes/Layers/Layer/Report/index";
import LayerStart from "./scenes/Layers/LayerStart";
import Mailbox from "./scenes/Mailbox/Mailbox";
import PackingListList from "./scenes/PackingList/PackingListList";
import PowerBI from "./scenes/PowerBi/PowerBI";
import RipeningRoom from "./scenes/Ripening/RipeningRoom";
import RipeningCellsTableWrapped from "./scenes/Ripening/RipeningRoomTable";
import Settings from "./scenes/Settings";
import Header from "./scenes/SupplierPortal/Header";
import SupplierPortal from "./scenes/SupplierPortal/SupplierPortal";
import Tenants from "./scenes/Tenants";
import TenantEditConfig from "./scenes/Tenants/TenantEditConfig";
import TenantEditDefectsForm from "./scenes/Tenants/TenantEditDefectsForm";
import TenantEditFacilityLocationsForm from "./scenes/Tenants/TenantEditFacilityLocationsForm";
import TenantEditForm from "./scenes/Tenants/TenantEditForm";
import TenantEditOptionsForm from "./scenes/Tenants/TenantEditOptionsForm";
import { isSupplierPortal } from "./utils";

const sharedRoutes = [
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/logout",
        element: <Logout />,
    },
];

const appRoutes = [
    ...sharedRoutes,
    {
        path: "/",
        element: <RequireAuth fullscreen={true} element={<LayerStart />} />,
    },
    {
        path: "/layer",
        children: [
            {
                index: true,
                element: <RequireAuth fullscreen={true} element={<LayerStart />} />,
            },
            {
                path: "tab/:tab_category/:active_tab",
                element: <RequireAuth fullscreen={true} element={<LayerStart />} />,
            },
            {
                path: "tab/:tab_category/:active_tab/edit-filter/:edit_field_filter",
                element: <RequireAuth fullscreen={true} element={<LayerStart />} />,
            },
            {
                path: "tab/:tab_category/:active_tab/cta/:cta_layer_id",
                element: <RequireAuth fullscreen={true} element={<LayerStart />} />,
            },
            {
                path: ":layer_id",
                element: <RequireAuth fullscreen={true} element={<LayerIndex />} />,
            },

            // * Layer reports, template = intake, store or advance_ripening
            {
                path: ":layer_id/report/:config_value",
                element: <RequireAuth fullscreen={true} disableRefresh={true} element={<PDFLayerReport />} />,
            },
            {
                path: ":layer_id/add-check/:test_id/:action/modal/:modal_type",
                element: <RequireAuth fullscreen={true} element={<Check />} />,
            },
            {
                path: ":layer_id/add-check/:test_id/:action/:avocado_id",
                element: <RequireAuth fullscreen={true} element={<Check />} />,
            },
            // * Generic check summary reports. template=last_check
            {
                path: ":layer_id/add-check/:test_id/report",
                element: <RequireAuth fullscreen={true} disableRefresh={true} element={<PDFCheckReport />} />,
            },
            {
                path: ":layer_id/add-check/:test_id/:action",
                element: <RequireAuth fullscreen={true} element={<Check />} />,
            },
            {
                path: ":layer_id/add-check/:test_id",
                element: <RequireAuth fullscreen={true} element={<Check />} />,
            },
            {
                path: ":layer_id/action/:action",
                element: <RequireAuth fullscreen={true} element={<LayerIndex />} />,
            },
        ],
    },
    {
        path: "/settings",
        element: <RequireAuth element={<Settings />} />,
    },
    {
        path: "/tenants",
        children: [
            {
                index: true,
                element: <RequireAuth element={<Tenants />} />,
            },
            {
                path: ":tenant_id",
                element: <RequireAuth element={<TenantEditForm />} />,
            },
            {
                path: ":tenant_id/tab/:config_tab",
                element: <RequireAuth element={<TenantEditForm />} />,
            },
            {
                path: ":tenant_id/form-field-options/defects", // * alias for defects
                element: <RequireAuth element={<TenantEditDefectsForm />} />,
            },
            {
                path: ":tenant_id/form-field-options/:field",
                element: <RequireAuth element={<TenantEditOptionsForm />} />,
            },
            {
                path: ":tenant_id/defects/:fruit_type",
                element: <RequireAuth element={<TenantEditDefectsForm />} />,
            },
            {
                path: ":tenant_id/facility-locations",
                element: <RequireAuth element={<TenantEditFacilityLocationsForm/>} />,
            },
            {
                path: ":tenant_id/config/:config_id",
                element: <RequireAuth element={<TenantEditConfig />} />,
            },
        ],
    },
    {
        path: "/labelling",
        children: [
            {
                index: true,
                element: <RequireAuth element={<LabellingStart />} />,

            },
            {
                path: "new",
                element: <RequireAuth element={<LabellingNewSession />} />,
            },
            {
                path: ":session_id",
                element: <RequireAuth element={<LabellingManualSession />} />,
            },

        ],
    },
    {
        path: "/device-managment",
        children: [
            {
                index: true,
                element: <RequireAuth element={<DeviceManagment />} />,
            },
            {
                path: ":device_uid/view/:view",
                element: <RequireAuth element={<DeviceConfiguration />} />,
            },
            {
                path: ":device_uid",
                element: <RequireAuth element={<DeviceConfiguration />} />,
            },
        ],
    },
    {
        path: "/power-bi",
        children: [{
            index: true,
            element: <RequireAuth element={<PowerBI />} />,
        }],
    },

    {
        path: "/packing-list",
        children: [{
            index: true,
            element: <RequireAuth element={<PackingListList />} />,
        }],
    },
    {
        path: "/mailbox",
        children: [{
            index: true,
            element: <RequireAuth element={<Mailbox />} />,
        }],
    },
    {
        path: "/ripening-room",
        children: [
            {
                index: true,
                element: <RequireAuth fullscreen={true} blueBackground={true} element={<RipeningCellsTableWrapped />} />,
            },
            {
                path: ":location_id/from/:from_date/to/:to_date",
                element: <RequireAuth fullscreen={true} blueBackground={true} element={<RipeningRoom />} />,
            },
            {
                path: ":location_id/from/:from_date/to/:to_date/edit/:edit_date",
                element: <RequireAuth fullscreen={true} blueBackground={true} element={<RipeningRoom />} />,
            },
            {
                path: ":location_id",
                element: <RequireAuth fullscreen={true} blueBackground={true} element={<RipeningRoom />} />,
            },
        ]
    },
    {
        path: "/admin/*",
        element: <RequireAuth element={<AdminPage />} />,
    },
    {
        path: "*",
        element: <NoMatch404 />
    },
];

const supplierPortalRoutes = [
    ...sharedRoutes,
    {
        path: "/",
        element: <RequireAuth element={<SupplierPortal />} header={<Header />} getUser={getSupplierPortalUser} />,
    },
    {
        path: "*",
        element: <NoMatch404 />,
    },
];

export default function MainContent() {
    // Determine which route configuration to use
    const items = isSupplierPortal() ? supplierPortalRoutes : appRoutes;
    const router = useRoutes(items);
    // TODO: wrap this in ConfigProvider... else it will load config all the time??
    return router;
}
