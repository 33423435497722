/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LAYER_TYPE } from "../../../../actions/Layers/constants";
import { applyDisplayFilterFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { getFormOptionsLabel } from "../../../../actions/Tenants/config/configHook";
import { find_category } from "../../../../actions/Tenants/config/utils";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import { toPercentage } from "../../Layers/fields/formatters";
import useRipeningRoom, { formatDate, getAllocatedLayersHook, isItemAllocatedOnDateHook } from "./hooks/useRipeningRoom";

// * Components to show as cards
export const MetricCardComponents = {
    number_of_purchase_orders: MetricCardNumberOfPurchaseOrders,
    number_of_ggn: MetricCardUniqueGGN,
    number_of_sizes: MetricCardUniqueSize,
    number_of_suppliers: MetricCardSuppliers,
    number_of_fruit_varieties: MetricCardFruitVariety,
    number_of_countries: MetricCardCountries,
    age_average: MetricCardAgeAverage,
    occupancy_latest: MetricCardOccupancyLatest,
    dry_matter: MetricCardAverageDryMatter,
};
// * Components to show as meta rows in table in a side bar
export const MetaRowComponents = {
    number_of_purchase_orders: MetaRowNumberOfPurchaseOrders,
    number_of_ggn: MetaRowUniqueGGN,
    number_of_sizes: MetaRowUniqueSize,
    number_of_suppliers: MetaRowSuppliers,
    number_of_countries: MetaRowCountries,
    number_of_fruit_varieties: MetaRowFruitVariety,
    age_average: MetaRowAgeAverage,
    occupancy_latest: MetaRowOccupancyLatest,
    dry_matter: MetaRowAverageDryMatter,
};

export interface MetricCardProps {
    title: string,
    children: any,
    color?: string | null
    description?: any

}

interface CustomMetricCardProps {
    metric: {
        flags: object[],
        label: string,
        default_flag: string
    },
    // eslint-disable-next-line react/no-unused-prop-types
    pallets?: any[], // TODO: why is it complaining that it is not being used
    // eslint-disable-next-line react/no-unused-prop-types
    firstWeekDay?: dayjs.Dayjs
}


function getFlagColor(value, flags, default_flag) {
    const flag = find_category(value, flags);
    if (flag) {
        return flag.flag;
    }
    return default_flag;

}

export default function RipeWiseMetricCardsGroup() {
    const config = useConfig();
    const { pallets, firstWeekDay, fruit_type } = useRipeningRoom();
    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    const activePallets = pallets.filter((p) => isItemAllocatedOnDate(p.id, firstWeekDay));


    const metrics = applyDisplayFilterFields(
        config?.root_config?.ripening_room_metric_cards,
        { fruit_type }
    );

    if (!isFilledArray(metrics)) {
        return null;
    }

    return (
        <div className="pb-5">
            <div className={`d-flex align-items-start pt-3 mx-n2 flex-nowrap ${metrics.length > 5 ? "justify-content-between" : "justify-content-start"}`}>
                {metrics.map((i, index) => {
                    const MetricComponent = MetricCardComponents[i.key];
                    if (!MetricComponent) {
                        // eslint-disable-next-line no-console
                        console.log(`MetricCardComponent not found for key: ${i.key}`);
                        return null;
                    }
                    return <MetricComponent key={index} metric={i} pallets={activePallets} firstWeekDay={firstWeekDay} />;
                })}
            </div>
        </div>
    );
}

export function RipeWiseMetricMetaTable() {
    const config = useConfig();
    const { pallets, firstWeekDay, fruit_type } = useRipeningRoom();
    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    const activePallets = pallets.filter((p) => isItemAllocatedOnDate(p.id, firstWeekDay));


    const metrics = applyDisplayFilterFields(
        config?.root_config?.ripening_room_metric_cards,
        { fruit_type }
    );

    if (!isFilledArray(metrics)) {
        return null;
    }

    return <table className="w-100">

        <tbody>
            {metrics.map((i, index) => {
                const MetricComponent = MetaRowComponents[i.key];
                if (!MetricComponent) {
                    // eslint-disable-next-line no-console
                    console.log(`MetricCardComponent not found for key: ${i.key}`);
                    return null;
                }
                return <MetricComponent key={index} metric={i} pallets={activePallets} firstWeekDay={firstWeekDay} />;
            })}
        </tbody>
    </table>;
}


function MetricCard({ title, children, color, description }: MetricCardProps) {
    const [modal, setModal] = useState(false);
    return (
        <>
            <div onClick={() => setModal(true)} css={css`border-radius:8px; width: 20%; `} className={`p-3 mx-2 overflow-hidden  position-relative bg-white card-alert --flag-${color}`} >
                {description && <span css={css`position: absolute; top: 2%; right: 4%; opacity: 0.4;`}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </span>}
                <div className="d-flex align-items-center flex-wrap py-1 display-4 text-nowrap text-truncate">
                    {children}
                </div>
                <div className="text-secondary font-weight-bold text-truncate"><b>{title}</b></div>
            </div>
            {description && <Modal isOpen={modal} toggle={() => setModal(false)}>
                <ModalHeader toggle={() => setModal(false)}>Description</ModalHeader>
                <ModalBody>{description}</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            }
        </>
    );
}

function MetricMetaRow({ title, children, color, description }: MetricCardProps) {
    const [modal, setModal] = useState(false);

    const getIcon = (color) => {
        if (color === "success") {
            return <span className="text-success">
                <FontAwesomeIcon icon={faInfoCircle} />
            </span>;
        }
        if (color === "warning") {
            return <span className="text-warning">
                <FontAwesomeIcon icon={faTriangleExclamation} />
            </span>;
        }
        if (color === "danger") {
            return <span className="text-danger">
                <FontAwesomeIcon icon={faTriangleExclamation} />
            </span>;
        }
        return <span css={css`opacity: 0.4;`}>
            <FontAwesomeIcon icon={faInfoCircle} />
        </span>;
    };
    return <tr>
        <td className="pb-2 pe-3 align-top fw-bold text-nowrap">{title}</td>
        <td className="pb-2 text-end align-top" onClick={() => setModal(true)} >
            {children}
        </td>
        <td className="pb-2 align-top text-end" onClick={() => setModal(true)}>
            {getIcon(color)}
            {description && <Modal isOpen={modal} toggle={() => setModal(false)}>
                <ModalHeader toggle={() => setModal(false)}>Description</ModalHeader>
                <ModalBody>{description}</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            }
        </td>
    </tr>;
}


function MetricCardUniqueGGN({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Global Gap Numbers on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.global_gap_number).map((i) => i.global_gap_number));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}>{uniqueCount}</MetricCard>);
}

function MetaRowUniqueGGN({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Global Gap Numbers on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.global_gap_number).map((i) => i.global_gap_number));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricMetaRow title={metric.label} color={flag} description={description}>{uniqueCount}</MetricMetaRow>);
}

function MetricCardUniqueSize({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Sizes on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.box_size).map((i) => i.box_size));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

function MetaRowUniqueSize({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Sizes on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.box_size).map((i) => i.box_size));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricMetaRow title={metric.label} color={flag} description={description}> {Array.from(unique).join(", ")} </MetricMetaRow>);
}

function MetricCardFruitVariety({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Fruit Varieties on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.fruit_variety).map((i) => i.fruit_variety));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

function MetaRowFruitVariety({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Fruit Varieties on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i.fruit_variety).map((i) => i.fruit_variety));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricMetaRow title={metric.label} color={flag} description={description}>{Array.from(unique).map((i) => getFormOptionsLabel("fruit_variety", i)).join(", ")}</MetricMetaRow>);
}

function MetricCardAgeAverage({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the age on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const harvestDates = (pallets || [])
        .filter((i) => i.harvest_date)
        .map((i) => new Date(i.harvest_date).getTime());

    if (harvestDates.length === 0) {
        return <MetricCard title={metric.label} description={description}>0</MetricCard>;
    }

    const min_harvest_date = new Date(Math.min(...harvestDates));
    const max_harvest_date = new Date(Math.max(...harvestDates));

    const max_age = dayjs().diff(min_harvest_date, "day");
    const min_age = dayjs().diff(max_harvest_date, "day");

    return (<MetricCard title={metric.label} description={description}>{min_age} - {max_age}</MetricCard>);
}

function MetaRowAgeAverage({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the age on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const harvestDates = (pallets || [])
        .filter((i) => i.harvest_date)
        .map((i) => new Date(i.harvest_date).getTime());

    if (harvestDates.length === 0) {
        return <MetricMetaRow title={metric.label} description={description}>-</MetricMetaRow>;
    }

    const min_harvest_date = new Date(Math.min(...harvestDates));
    const max_harvest_date = new Date(Math.max(...harvestDates));

    const max_age = dayjs().diff(min_harvest_date, "day");
    const min_age = dayjs().diff(max_harvest_date, "day");

    return (<MetricMetaRow title={metric.label} description={description}>{min_age}d - {max_age}d</MetricMetaRow>);
}

function MetricCardAverageDryMatter({ metric }: CustomMetricCardProps) {
    const { firstWeekDay } = useRipeningRoom();
    const getAllocatedLayers = getAllocatedLayersHook();
    const layers = getAllocatedLayers(firstWeekDay);
    const description = <span>Displays the range of average Dry Matter value from lab checks.</span>;

    const dryMatterValues = getFilledArrayOrDefault(layers).map((i) => i.lab_check_average_dry_matter).filter((i) => i).map((i) => Number(i));

    if (dryMatterValues.length === 0) {
        return <MetricCard title={metric.label} description={description}>-</MetricCard>;
    }

    const min_dry_matter = Math.min(...dryMatterValues);
    const max_dry_matter = Math.max(...dryMatterValues);
    const flag = getFlagColor(min_dry_matter, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}>{min_dry_matter} - {max_dry_matter}</MetricCard>);
}

function MetaRowAverageDryMatter({ metric }: CustomMetricCardProps) {
    const { firstWeekDay } = useRipeningRoom();
    const getAllocatedLayers = getAllocatedLayersHook();
    const layers = getAllocatedLayers(firstWeekDay);
    const description = <span>Displays the range of average Dry Matter value from lab checks.</span>;

    const dryMatterValues = getFilledArrayOrDefault(layers).map((i) => i.lab_check_average_dry_matter).filter((i) => i).map((i) => Number(i));

    if (dryMatterValues.length === 0) {
        return <MetricMetaRow title={metric.label} description={description}>-</MetricMetaRow>;
    }

    const min_dry_matter = Math.min(...dryMatterValues);
    const max_dry_matter = Math.max(...dryMatterValues);
    const flag = getFlagColor(min_dry_matter, metric.flags, metric.default_flag);

    return (<MetricMetaRow title={metric.label} color={flag} description={description}>{min_dry_matter} - {max_dry_matter}</MetricMetaRow>);
}


function MetricCardOccupancyLatest({ metric, firstWeekDay }: CustomMetricCardProps) {
    const current = useSelector((state: any) => state.facilityLocations.current);
    const description = <span>This metric shows the occupancy on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const getAllocatedLayers = getAllocatedLayersHook();
    const loaded = getAllocatedLayers(firstWeekDay, LAYER_TYPE.PALLET).length;

    return (<MetricCard title={metric.label} description={description}>{toPercentage(loaded, current.capacity)} </MetricCard>);
}

function MetaRowOccupancyLatest({ metric, firstWeekDay }: CustomMetricCardProps) {
    const current = useSelector((state: any) => state.facilityLocations.current);
    const { today } = useRipeningRoom();
    const description = <span>This metric shows the occupancy on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const getAllocatedLayers = getAllocatedLayersHook();
    const loaded = getAllocatedLayers(today, LAYER_TYPE.PALLET).length;

    return (<MetricMetaRow title={metric.label} description={description}>{toPercentage(loaded, current.capacity)} </MetricMetaRow>);
}


function MetricCardNumberOfPurchaseOrders({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique PO on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set((pallets || []).flatMap((i) => i.parents.map((p) => p.id)));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

function MetaRowNumberOfPurchaseOrders({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique PO on <b>{formatDate(firstWeekDay)}</b>.</span>;
    const parents = (pallets || []).flatMap((i) => i.parents.map(({ id, label }) => ({ id, label })));
    const unique = new Set((parents || []).map((i) => i.id));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    // Generate layer links with commas as separators
    const layerLinks = Array.from(unique).map((id, index) => {
        const label = parents.find((p) => p.id === id)?.label || id;
        return (
            <React.Fragment key={id}>
                <Link to={`/layers/${id}`}>{label}</Link>
                {index < unique.size - 1 && ", "}
            </React.Fragment>
        );
    });

    return (<MetricMetaRow title={metric.label} color={flag} description={description}>{layerLinks}</MetricMetaRow>);
}


function MetricCardSuppliers({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {

    const description = <span>This metric shows the count of unique Suppliers on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set((pallets || []).flatMap((i) => i.supplier));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}


function MetaRowSuppliers({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Suppliers on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set((pallets || []).flatMap((i) => i.supplier));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricMetaRow title={metric.label} color={flag} description={description}> {Array.from(unique).map((i) => getFormOptionsLabel("supplier", i)).join(", ")} </MetricMetaRow>);
}

function MetricCardCountries({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Countries on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set((pallets || []).flatMap((i) => i.country));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricCard title={metric.label} color={flag} description={description}> {uniqueCount} </MetricCard>);
}

function MetaRowCountries({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique Countries on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set((pallets || []).flatMap((i) => i.country));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    return (<MetricMetaRow title={metric.label} color={flag} description={description}> {Array.from(unique).map((i) => getFormOptionsLabel("country", i)).join(", ")} </MetricMetaRow>);
}
